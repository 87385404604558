import { Checkbox, Input, Select, InputNumber, DatePicker, Tag } from "antd";
import { UserSelect } from "src/components";
import dayjs from "dayjs";

export const FieldRenderer = ({
  field,
  value,
  editMode,
  onChange = (key, value) => {},
  onSave = (key, value) => {},
  isRowSetup = false, // Pass a prop to indicate if this is in row setup,
  rowNumber = 2,
}) => {
  let dateValue = value;
  const handleChange = (newValue) => {
    onChange(field.key, newValue);
  };

  const handleSave = (newValue) => {
    if (field.type === "timestamp" && isRowSetup) {
      onSave(field.key, dateValue); // Save local state only for timestamp in row setup
    } else {
      onSave(field.key, newValue); // Save normally for other types
    }
  };

  const renderValue = (val) => {
    if (field.renderValue) {
      return field.renderValue(val);
    }

    switch (field.type) {
      case "boolean":
        return <Checkbox checked={!!val} disabled />;

      case "select":
        return val ? (
          <Tag color="blue">
            {field.options?.find((opt) => opt === val) || val}
          </Tag>
        ) : null;

      case "timestamp":
        return val ? dayjs(val).format("YYYY-MM-DD") : null;

      case "currency":
        return val ? (
          <span>
            $
            {Number(val).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        ) : null;

      case "number":
        return val ? <span>{Number(val).toLocaleString("en-US")}</span> : null;

      case "user":
        return val ? <Tag color="purple">{val}</Tag> : null;

      default:
        return val || "";
    }
  };

  if (!editMode) {
    // Handle array values first
    if (Array.isArray(value)) {
      const limitedItems = value.slice(0, 4);
      return (
        <div>
          {limitedItems.map((item, index) => (
            <div key={index}>{renderValue(item)}</div>
          ))}
          {value.length > 4 && <div>...and {value.length - 4} more</div>}
        </div>
      );
    }

    return renderValue(value);
  }

  switch (field.type) {
    case "boolean":
      return (
        <Checkbox
          checked={!!value}
          onChange={(e) => {
            handleChange(e.target.checked);
            handleSave(e.target.checked);
          }}
        />
      );

    case "select":
      return (
        <Select
          value={value || ""}
          onChange={(newValue) => {
            handleChange(newValue);
            handleSave(newValue);
          }}
          dropdownStyle={{ maxHeight: 200 }} // Adjust dropdown height here
          style={{ width: "100%" }} // Adjust the width of the select
        >
          {(field.options || []).map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      );

    case "number":
      return (
        <InputNumber
          value={value || 0}
          onChange={(newValue) => handleChange(newValue)}
          onBlur={(newValue) => handleSave(newValue)}
          onMouseLeave={(newValue) => handleSave(newValue)}
          placeholder="0"
        />
      );

    case "timestamp":
      return (
        <DatePicker
          value={dateValue ? dayjs(dateValue) : undefined}
          onChange={(date, dateString) => {
            dateValue = dateString;
            handleChange(dateString);
          }}
          onOpenChange={(open) => {
            if (isRowSetup && !open) {
              handleSave(dateValue);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && isRowSetup) {
              handleSave(dateValue); // Save when the user presses "Enter"
            }
          }}
          placeholder="Select Date"
          minDate={dayjs(new Date())}
        />
      );

    case "currency":
      return (
        <InputNumber
          value={value || 0}
          onChange={(newValue) => handleChange(newValue)}
          onBlur={(newValue) => handleSave(newValue)}
          onMouseLeave={(e) =>
            handleSave((e.target as HTMLTextAreaElement).value)
          }
          placeholder="0"
        />
      );
    case "user":
      return (
        <UserSelect
          handleUser={(newValue) => {
            handleChange(newValue?.email);
            handleSave(newValue?.email);
          }}
          defaultUser={value} // Adjust dropdown height here
        />
      );

    default:
      return (
        <Input.TextArea
          rows={rowNumber}
          value={value || ""}
          onChange={(e) =>
            handleChange((e.target as HTMLTextAreaElement).value)
          }
          onMouseLeave={(e) =>
            handleSave((e.target as HTMLTextAreaElement).value)
          }
          onBlur={(e) => handleSave((e.target as HTMLTextAreaElement).value)}
        />
      );
  }
};
