import { Select, Form, Input, DatePicker } from "antd";
import { useOrganizationState } from "src/state/OrganizationState";
import { TasksCustomFields } from "../Profile/Components/Settings/CustomField/CustomField.types";

// Generate the form items dynamically
export const RenderFormFields = (taskFields: TasksCustomFields) => {
  const { organizationSettings, collaborators } = useOrganizationState();
  return Object.values(taskFields).map((field) => {
    switch (field.type) {
      case "select":
        return (
          <Form.Item
            label={field.fieldName}
            name={field.key}
            key={field.key}
            rules={[
              {
                required: field.required,
                message: `Please select ${field.fieldName}!`,
              },
            ]}
          >
            <Select>
              {organizationSettings[field?.listName]?.map((item) => (
                <Select.Option value={item} key={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );

      case "string":
        return (
          <Form.Item
            label={field.fieldName}
            name={field.key}
            key={field.key}
            rules={[
              {
                required: field.required,
                message: `Please enter ${field.fieldName}!`,
              },
            ]}
          >
            <Input placeholder={field.fieldName} />
          </Form.Item>
        );

      case "timestamp":
        return (
          <Form.Item
            label={field.fieldName}
            name={field.key}
            key={field.key}
            rules={[
              {
                required: field.required,
                message: `Please select ${field.fieldName}!`,
              },
            ]}
          >
            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
        );

      case "user":
        return (
          <Form.Item label={field.fieldName} name={field.key} key={field.key}>
            <Select
              placeholder="Select user(s)"
              mode="multiple" // Enables multiple selection
              showSearch
              allowClear
              options={collaborators?.map((c) => ({
                label: c.email, // The label to display in the dropdown
                value: c.email, // The value that will be saved
                key: c.uid, // Ensure the option has a unique key
              }))}
            />
          </Form.Item>
        );

      default:
        return null;
    }
  });
};
