import React from "react";
import { Space, Card, Row, Col, Statistic, Progress } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { Chart } from "react-google-charts";
import { useFetchInvoices } from "src/hooks/useFetchData";
import { useOrganizationState } from "src/state/OrganizationState";
import { color } from "src/styles/variables";

export const InvoiceDashboard = () => {
  const { organization } = useOrganizationState();
  const {
    data: invoices,
    loading: loadingInvoices,
    refetch,
  } = useFetchInvoices(organization);

  if (loadingInvoices || !organization) {
    return (
      <div style={{ padding: "24px", textAlign: "center" }}>
        Loading invoices...
      </div>
    );
  } else if (!invoices) {
    return (
      <div style={{ padding: "24px", textAlign: "center" }}>
        No invoices found for this organization.
      </div>
    );
  }

  // Calculate metrics
  const avgDSO =
    invoices?.data?.reduce((sum, inv) => sum + inv.dso, 0) /
      invoices?.data?.length || 0;
  const contactSuccessRate =
    (invoices?.data?.reduce((sum, inv) => {
      const successful = inv.contactAttempts.filter(
        (attempt) => attempt.successful,
      ).length;
      return sum + successful / inv.contactAttempts.length;
    }, 0) /
      invoices?.data?.length) *
    100;

  const disputeReasons =
    invoices?.data?.reduce(
      (acc, inv) => {
        if (inv?.disputeInfo?.reason) {
          acc[inv.disputeInfo.reason] = (acc[inv.disputeInfo.reason] || 0) + 1;
        }
        return acc;
      },
      {} as Record<string, number>,
    ) || {};

  // Calculate aging buckets
  const agingBuckets =
    invoices?.data?.reduce(
      (acc, inv) => {
        const today = new Date();
        const dueDate = new Date(inv.dueDate);
        const daysOverdue = Math.floor(
          (today.getTime() - dueDate.getTime()) / (1000 * 60 * 60 * 24),
        );

        if (daysOverdue <= 30) acc["0-30"] = (acc["0-30"] || 0) + 1;
        else if (daysOverdue <= 60) acc["31-60"] = (acc["31-60"] || 0) + 1;
        else if (daysOverdue <= 90) acc["61-90"] = (acc["61-90"] || 0) + 1;
        else acc["90+"] = (acc["90+"] || 0) + 1;

        return acc;
      },
      {} as Record<string, number>,
    ) || {};

  return (
    <div style={{ padding: "24px" }}>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Card
                bordered={false}
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
                }}
              >
                <Statistic
                  title={"Average DSO"}
                  value={avgDSO}
                  suffix="days"
                  precision={1}
                  valueStyle={{
                    color: color.blue,
                    fontSize: "24px",
                    fontWeight: 600,
                  }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Card
                bordered={false}
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
                }}
              >
                <Statistic
                  title={"Contact Success Rate"}
                  value={contactSuccessRate}
                  suffix="%"
                  precision={1}
                  valueStyle={{
                    color: contactSuccessRate > 50 ? color.green : color.red,
                    fontSize: "24px",
                    fontWeight: 600,
                  }}
                  prefix={
                    contactSuccessRate > 50 ? (
                      <ArrowUpOutlined />
                    ) : (
                      <ArrowDownOutlined />
                    )
                  }
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Card
                bordered={false}
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
                }}
              >
                <Statistic
                  title={"Open Compliance Issues"}
                  value={invoices?.data?.reduce(
                    (sum, inv) =>
                      sum +
                      inv.complianceIssues?.filter(
                        (issue) =>
                          issue.resolvedDate === null ||
                          issue.resolvedDate === undefined,
                      ).length,
                    0,
                  )}
                  valueStyle={{
                    color: color.red,
                    fontSize: "24px",
                    fontWeight: 600,
                  }}
                />
              </Card>
            </Col>
          </Row>

          <Card
            title={"Promise to Pay Fulfillment"}
            style={{
              marginTop: "24px",
              height: "580px",
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
            }}
            bordered={false}
            bodyStyle={{ padding: "16px" }}
          >
            <Chart
              chartType="ComboChart"
              data={[
                ["Date", "Promised", "Received", "Accounts"],
                ...(invoices?.data
                  ? Array.from(
                      invoices.data.reduce((acc, inv) => {
                        const date = new Date(
                          inv.promiseToPay?.promisedDate || inv.dueDate,
                        ).toLocaleDateString();
                        const existing = acc.get(date) || {
                          promised: 0,
                          received: 0,
                          count: 0,
                        };
                        existing.promised += inv.promiseToPay?.amount || 0;
                        existing.received +=
                          inv.paymentHistory?.reduce(
                            (sum, p) => sum + p.amount,
                            0,
                          ) || 0;
                        existing.count += 1;
                        acc.set(date, existing);
                        return acc;
                      }, new Map()),
                    )
                      .map(([date, data]) => [
                        date,
                        data.promised,
                        data.received,
                        data.count,
                      ])
                      .sort(
                        (a, b) =>
                          new Date(a[0]).getTime() - new Date(b[0]).getTime(),
                      )
                  : []),
              ]}
              options={{
                title: "",
                seriesType: "line",
                series: {
                  0: {
                    targetAxisIndex: 0,
                    color: color.orange,
                    lineWidth: 2,
                    lineDashStyle: [4, 4],
                    pointShape: "circle",
                  },
                  1: {
                    targetAxisIndex: 0,
                    color: color.olive,
                    lineWidth: 2,
                    pointShape: "circle",
                  },
                  2: {
                    targetAxisIndex: 1,
                    color: color.yellow,
                    lineWidth: 1.5,
                    opacity: 0.7,
                    pointShape: "circle",
                  },
                },
                vAxes: {
                  0: {
                    title: "Amount ($)",
                    format: "currency",
                    gridlines: { color: "transparent" },
                    textStyle: { color: color.grayDark, fontSize: 11 },
                    titleTextStyle: {
                      color: color.grayLight,
                      fontSize: 12,
                      italic: false,
                    },
                    baselineColor: color.gray,
                  },
                  1: {
                    title: "Number of Accounts",
                    gridlines: { color: "transparent" },
                    textStyle: { color: color.grayDark, fontSize: 11 },
                    titleTextStyle: {
                      color: color.grayLight,
                      fontSize: 12,
                      italic: false,
                    },
                    baselineColor: color.gray,
                  },
                },
                hAxis: {
                  title: "",
                  gridlines: { color: "transparent" },
                  baselineColor: color.gray,
                  format: "MMM d",
                  textStyle: { color: color.grayDark, fontSize: 11 },
                  slantedText: true,
                  slantedTextAngle: 30,
                  maxAlternation: 1,
                },
                legend: {
                  position: "top",
                  alignment: "center",
                  textStyle: {
                    fontSize: 12,
                    color: color.grayDark,
                  },
                },
                chartArea: {
                  width: "88%",
                  height: "80%",
                  backgroundColor: {
                    fill: "transparent",
                  },
                },
                pointSize: 4,
                backgroundColor: "transparent",
                animation: {
                  startup: true,
                  duration: 500,
                  easing: "out",
                },
                tooltip: {
                  textStyle: { fontSize: 12 },
                  showColorCode: true,
                },
                focusTarget: "category",
              }}
              width="100%"
              height="480px"
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Space direction="vertical" style={{ width: "100%" }} size={24}>
            {disputeReasons?.length && (
              <Card
                title={"Dispute Reason Breakdown"}
                style={{
                  height: "260px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
                }}
                bordered={false}
                bodyStyle={{ padding: "12px" }}
              >
                <Chart
                  chartType="PieChart"
                  data={[
                    ["Reason", "Count"],
                    ...Object.entries(disputeReasons || {}).map(
                      ([reason, count]) => [
                        reason.replace("_", " ").toUpperCase(),
                        count,
                      ],
                    ),
                  ]}
                  options={{
                    title: "",
                    pieHole: 0.4,
                    is3D: false,
                    slices: {
                      0: { color: color.olive },
                      1: { color: color.orange },
                      2: { color: color.green },
                      3: { color: color.yellow },
                    },
                    legend: {
                      position: "right",
                      alignment: "center",
                      textStyle: { fontSize: 11 },
                    },
                    chartArea: { width: "70%", height: "85%" },
                    backgroundColor: "transparent",
                  }}
                  width="100%"
                  height="160px"
                />
              </Card>
            )}

            {agingBuckets?.length && (
              <Card
                title={
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: color.grayDark,
                    }}
                  >
                    Aging Buckets
                  </div>
                }
                style={{
                  height: "260px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
                }}
                bordered={false}
                bodyStyle={{ padding: "12px" }}
              >
                <Chart
                  chartType="ColumnChart"
                  data={[
                    ["Aging", "Accounts"],
                    ...Object.entries(agingBuckets || {})
                      .sort((a, b) => {
                        const aNum = parseInt(a[0].split("-")[0]);
                        const bNum = parseInt(b[0].split("-")[0]);
                        return aNum - bNum;
                      })
                      .map(([bucket, count]) => [bucket, count]),
                  ]}
                  options={{
                    title: "",
                    legend: { position: "none" },
                    colors: [color.blue],
                    bar: { groupWidth: "70%" },
                    vAxis: {
                      title: "Number of Accounts",
                      gridlines: { color: "transparent" },
                      textStyle: { color: color.grayDark, fontSize: 11 },
                      titleTextStyle: {
                        color: color.grayLight,
                        fontSize: 12,
                        italic: false,
                      },
                      baselineColor: color.gray,
                      minValue: 0,
                    },
                    hAxis: {
                      title: "Days Past Due",
                      gridlines: { color: "transparent" },
                      textStyle: { color: color.grayDark, fontSize: 11 },
                      titleTextStyle: {
                        color: color.grayLight,
                        fontSize: 12,
                        italic: false,
                      },
                      baselineColor: color.gray,
                    },
                    chartArea: { width: "80%", height: "75%" },
                    backgroundColor: "transparent",
                    animation: {
                      startup: true,
                      duration: 500,
                      easing: "out",
                    },
                  }}
                  width="100%"
                  height="160px"
                />
              </Card>
            )}
            <Card
              title={"Contact Attempt Success Rate"}
              style={{
                height: "260px",
                borderRadius: "8px",
                boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
              }}
              bordered={false}
              bodyStyle={{
                padding: "16px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <Space direction="vertical" style={{ width: "100%" }} size={20}>
                {["email", "phone", "sms"].map((type) => {
                  const attempts =
                    invoices?.data?.flatMap((inv) =>
                      inv.contactAttempts.filter(
                        (attempt) => attempt.type === type,
                      ),
                    ) || [];
                  const successfulAttempts = attempts.filter(
                    (a) => a.successful,
                  ).length;
                  const successRate = attempts.length
                    ? (successfulAttempts / attempts.length) * 100
                    : 0;

                  return (
                    <div key={type}>
                      <div
                        style={{
                          marginBottom: 8,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            textTransform: "uppercase",
                            fontSize: "14px",
                            fontWeight: 500,
                            color: color.grayDark,
                          }}
                        >
                          {type}
                        </span>
                        <span
                          style={{
                            color: color.grayLight,
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {successfulAttempts}/{attempts.length}
                        </span>
                      </div>
                      <Progress
                        percent={successRate}
                        strokeColor={
                          successRate >= 70
                            ? color.green
                            : successRate >= 40
                              ? color.yellow
                              : color.red
                        }
                        strokeWidth={10}
                        showInfo={false}
                      />
                    </div>
                  );
                })}
              </Space>
            </Card>
          </Space>
        </Col>
      </Row>
    </div>
  );
};
