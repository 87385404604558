import DOMPurify from "dompurify";

export const cleanBody = (body) => {
  const sanitizedBody = DOMPurify.sanitize(body, {
    ALLOWED_TAGS: [
      "a",
      "b",
      "i",
      "u",
      "strong",
      "em",
      "p",
      "ol",
      "ul",
      "li",
      "img",
      "br",
      "h1",
      "h2",
      "h3",
      "span",
      "pre",
      "code",
    ],
    ALLOWED_ATTR: ["href", "target", "src", "alt", "rel", "style"],
    ALLOWED_URI_REGEXP: /^data:image\/|https?:\/\//, // Allow base64 or valid URLs
  });

  return sanitizedBody
    .replace(
      /<pre><code>/g,
      '<div style="background-color: #f4f4f4; padding: 10px;">',
    )
    .replace(/<\/code><\/pre>/g, "</div>");
};
export const removeQuotedReply = (body) => {
  // Match a broader set of reply patterns
  const regex = /On\s.+,\s.+?(wrote:|sent:|replied:|says:)/i;
  return body.split(regex)[0].trim(); // Keep the part before the quoted reply
};

export const extractEmail = (input) => {
  const regex = /<([^>]+)>/; // Matches anything between < and >
  const match = input?.match(regex); // Use match to find the email
  return match ? match[1] : input; // Return the email if found, otherwise the original input
};
