import React, { useEffect, useState } from "react";
import { Card, Tooltip, Space } from "antd";
import {
  BankOutlined,
  LinkOutlined,
  PhoneOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import {
  User,
  AccountAvatar,
  FieldRenderer,
  TableBoardDashboard,
} from "src/components";
import {
  defaultOrganizationSettings,
  useOrganizationState,
} from "src/state/OrganizationState";
import {
  COLLECTION_DATA,
  LOCALSTORAGE_TYPES,
  QUERY_PARAMS,
} from "src/utils/enums";
import {
  createOrUpdateDocInFirestore,
  getRecordsFromFireStore,
} from "src/firebaseAuth";
import { customerConverter } from "src/utils/converter";
import { useNavigate } from "react-router-dom";
import {
  capitalizeFirstLetters,
  formatDateToHumanReadable,
  humanize,
} from "src/utils/utils";
import { getCustomerIdentity, updateCustomerInfo } from "./Customers.utils";
import { HoverContainer } from "./Customers.styles";
import { useUserState } from "src/state/UserState";

export const CustomersDashboard = () => {
  const {
    organization,
    customers,
    setCustomers,
    organizationSettings,
    setOrganizationSettings,
  } = useOrganizationState();
  const { user } = useUserState();

  const { customersCustomFields } =
    organizationSettings || defaultOrganizationSettings;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(!customers?.data);

  const [stageData, setStageData] = useState({});
  const [tableConfig, setTableConfig] = useState(
    JSON.parse(
      localStorage.getItem(LOCALSTORAGE_TYPES.CUSTOMERS_TABLE_CONFIG),
    ) || {},
  );

  // Toggle drawer visibility
  const saveConfigToLocalStorage = (config) => {
    localStorage.setItem(
      LOCALSTORAGE_TYPES.CUSTOMERS_TABLE_CONFIG,
      JSON.stringify(config),
    );
    setTableConfig(config);
  };

  // Handle switch change for each custom field
  const handleSwitchChange = (fieldKey, checked) => {
    const updatedConfig = { ...tableConfig, [fieldKey]: checked };
    saveConfigToLocalStorage(updatedConfig);
  };
  const assignStage = async (value, rowId, oldStage, selectedCustomer) => {
    const updateData: any = {
      stage: value,
    };

    if (oldStage !== value && Boolean(oldStage)) {
      updateData.stageHistory = [
        {
          stage: oldStage,
          updatedBy: user.email,
          timestamp: new Date().toISOString(),
        },
        ...(selectedCustomer.stageHistory ?? []),
      ];
    }

    updateCustomerInfo(organization, rowId, updateData, setCustomers, {
      key: "stage",
    });
  };

  const columns = [
    {
      accessorFn: (row) => getCustomerIdentity(row),
      enableEditing: false,
      enableGrouping: false,
      header: "Customer Details",
      size: 200,
      Cell: ({ row }) => (
        <HoverContainer onClick={() => handleCustomerClick(row.original)}>
          <User user={row.original} usePhoneIcon={true} />
        </HoverContainer>
      ),
    },
    {
      header: "Account",
      enableEditing: false,
      enableGrouping: true, // Enable grouping
      accessorFn: (row) => row?.company || row?.companyDomain || "No Account", // Fallback to "No Account" for grouping
      size: 200,
      Cell: ({ row }) => {
        const account = {
          company: row.original.company,
          companyDomain: row.original.companyDomain,
          accountId: row.original.accountId,
        };
        return (
          <HoverContainer onClick={() => handleAccountClick(account)}>
            <AccountAvatar
              account={{
                name: account.company,
                domain: account.companyDomain,
              }}
            />
          </HoverContainer>
        );
      },
    },

    {
      header: "Update At",
      enableEditing: false,
      enableGrouping: false,
      accessorKey: "updatedAt", // accessor is the "key" in the data
      size: 150, // Adjust column width for better visibility
      Cell: ({ cell }) => {
        return formatDateToHumanReadable(cell.getValue());
      },
      enableColumnFilter: false,
    },
    {
      header: "# of Conversations",
      enableEditing: false,
      enableGrouping: false,
      accessorKey: "conversations", // accessor is the "key" in the data
      Cell: ({ cell }) => {
        const conversations = cell.getValue();
        return conversations?.length;
      },
      size: 100, // Adjust column width for better visibility
    },
    ...Object.values(customersCustomFields)
      .filter(
        (field) => field.key !== "company" && tableConfig[field.key] !== false,
      )
      .map((field) => ({
        header: humanize(field.key),
        accessorKey: field.key,
        enableEditing: field.editable,
        enableGrouping: false,
        size: field.type === "string" ? 200 : 100, // Adjust column width for better visibility
        Edit: ({ cell, row, table }) => {
          // This renders in edit mode
          const [inputValue, setInputValue] = useState(cell.getValue());

          return (
            <FieldRenderer
              field={field}
              value={inputValue}
              onChange={(key, value) => {
                row.original[key] = value;
                setInputValue(value);
              }}
              isRowSetup={true}
              onSave={(key, value) => {
                // Immediately save the value
                row.original[key] = value;
                updateCustomerInfo(
                  organization,
                  row.original.id,
                  { [key]: value },
                  setCustomers,
                  field,
                );

                // Exit edit mode
                table.setEditingCell(null);
              }}
              editMode={true}
            />
          );
        },
        Cell: ({ cell, row }) => {
          return (
            <Tooltip title="Double click to edit" arrow placement="top">
              <div style={{ cursor: "pointer" }}>
                <FieldRenderer
                  field={field}
                  value={cell.getValue()}
                  onChange={() => {}}
                  isRowSetup={true}
                  editMode={false}
                />
              </div>
            </Tooltip>
          );
        },
      })),
  ];

  const [paginationPerStage, setPaginationPerStage] = useState({});

  const fetchCustomers = async (stage = null) => {
    setLoading(true);

    const paginationState = stage
      ? paginationPerStage[stage] || { lastDoc: null, hasMore: true }
      : {
          lastDoc: customers?.lastDoc || null,
          hasMore: customers?.hasMore ?? true,
        };

    const filters: any = [
      {
        comparisonField: "updatedAt",
        order: "desc",
      },
    ];

    if (stage) {
      if (stage === "Unassigned Stage") {
        filters.push({
          comparisonField: "stage",
          comparisonOperator: "==",
          value: "",
        });
      } else {
        filters.push({
          comparisonField: "stage",
          comparisonOperator: "in",
          value: [capitalizeFirstLetters(stage), stage.toLowerCase()],
        });
      }
    }

    const docs = await getRecordsFromFireStore(
      `/organization/${organization}/${COLLECTION_DATA.CUSTOMERS}`,
      filters,
      customerConverter,
      undefined,
      50,
      paginationState.lastDoc,
    );

    if (stage) {
      // Update stage data

      const check =
        docs?.data?.filter(
          (newCustomer) =>
            !customers.data.find((customer) => customer.id === newCustomer.id),
        ) || [];

      const newData = [...customers.data, ...check];

      // Update overall customers list to include new customers
      setCustomers((prevState) => {
        const newCustomers =
          docs?.data?.filter(
            (newCustomer) =>
              !prevState.data.find(
                (customer) => customer.id === newCustomer.id,
              ),
          ) || [];

        return {
          ...prevState,
          data: [...prevState.data, ...newCustomers],
        };
      });
      setPaginationPerStage((prevPagination) => ({
        ...prevPagination,
        [stage]: {
          lastDoc: docs?.lastDoc || null,
          hasMore: docs?.data?.length >= 50,
        },
      }));
      updateStageData(newData);
    } else {
      setCustomers((prevState) => {
        const filteredNewData = docs?.data?.filter(
          (newCustomer) =>
            !prevState?.data?.find(
              (customer) => customer.id === newCustomer.id,
            ),
        );
        const newData = [...(prevState.data || []), ...filteredNewData];
        const hasMore = docs.data.length >= 50 && docs.data.length > 0;

        if (!hasMore) {
          setPaginationPerStage((prevPagination) => {
            const updatedPagination = { ...prevPagination };

            Object.keys(updatedPagination).forEach((stage) => {
              updatedPagination[stage] = {
                hasMore: false,
                lastDoc: null,
              };
            });
            return updatedPagination;
          });
        }

        return {
          ...prevState,
          data: newData.length > 0 ? newData : [],
          hasMore: hasMore,
          lastDoc: docs.lastDoc || null,
        };
      });
      // Update stage data
      updateStageData(docs.data);
    }

    setLoading(false);
  };

  const updateStageData = (customersData) => {
    const newStages = {
      "Unassigned Stage": [],
      ...(customersCustomFields?.stage?.options || []).reduce((acc, stage) => {
        acc[stage.toLowerCase()] = [];
        return acc;
      }, {}),
    };
    customersData?.forEach((customer) => {
      const stage = customer?.stage?.toLowerCase() || "Unassigned Stage";
      if (newStages[stage]) {
        newStages[stage].push(customer);
      }
    });

    setStageData(newStages);
    if (Object.keys(paginationPerStage).length === 0) {
      setPaginationPerStage((prevPagination) => {
        const updatedPagination = { ...prevPagination };

        Object.keys(newStages).forEach((stage) => {
          if (!updatedPagination[stage]) {
            updatedPagination[stage] = {
              hasMore: true,
              lastDoc: null,
            };
          }
        });
        return updatedPagination;
      });
    }
  };

  useEffect(() => {
    if (!organizationSettings || !organization) return;
    if (!customers?.data) {
      fetchCustomers();
    } else {
      updateStageData(customers.data);
    }
  }, [organization, customers?.data, organizationSettings]);

  const loadMoreCustomers = async () => {
    if (!customers.lastDoc) return;
    setLoading(true);
    try {
      fetchCustomers();
      setTimeout(() => {
        const lastBeforeNew = document.getElementById(
          `Customer-${customers.data[customers.data.length - 2]?.id}`,
        );
        if (lastBeforeNew) {
          lastBeforeNew.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    } catch (error) {
      console.error("Error fetching more transcripts: ", error);
    } finally {
      setLoading(false);
    }
  };
  const loadMoreCustomersPerStage = (stage) => {
    fetchCustomers(stage);
  };

  const handleCustomerClick = (customer) => {
    navigate(
      `?customer=${encodeURIComponent(customer.id)}&${QUERY_PARAMS.BREADCRUMB_TITLE}=${encodeURIComponent(customer.customer)}`,
    );
  };
  const handleAccountClick = (account) => {
    const { company, companyDomain, accountId } = account;
    const title = company || companyDomain;
    accountId &&
      navigate(
        `?account=${encodeURIComponent(accountId)}&${QUERY_PARAMS.BREADCRUMB_TITLE}=${encodeURIComponent(title)} `,
      );
  };
  const onDragEnd = async (result) => {
    const { source, destination, draggableId, type } = result;
    if (!destination) return;

    const sourceStage = source.droppableId;
    const destinationStage = destination.droppableId;

    if (
      sourceStage === destinationStage &&
      source.index === destination.index
    ) {
      return;
    }

    if (type === "Stage") {
      // @todo
      // const stagesArray = Object.keys(stageData);
      // const [removed] = stagesArray.splice(source.index, 1);
      // stagesArray.splice(destination.index, 0, removed);
      // // remove first unassigned stage

      // setStageData((prevData) => {
      //   const updatedData = stagesArray.reduce((acc, stage) => {
      //     acc[stage] = prevData[stage];
      //     return acc;
      //   }, {});
      //   return updatedData;
      // });

      // stagesArray.splice(0, 1);

      // const lowerCaseStages = stagesArray.map((stage) => stage.toLowerCase());
      // setOrganizationSettings((prevSettings) => {
      //   return {
      //     ...prevSettings,
      //     customersCustomFields: {
      //       ...customersCustomFields,
      //       stage: {
      //         ...customersCustomFields.stage,
      //         options: lowerCaseStages,
      //       },
      //     },
      //   };
      // });

      // await createOrUpdateDocInFirestore(`/organization/${organization}`, {
      //   "customersCustomFields.stage.options": stagesArray,
      // });

      return;
    }

    const customerData = customers.data.find(
      (customer) => customer.id === draggableId,
    );
    const updatedCustomer = {
      ...customerData,
      stage: destinationStage === "Unassigned Stage" ? "" : destinationStage,
      updatedAt: new Date().toISOString(),
    };

    setCustomers((prevstate) => {
      const updatedCustomers = prevstate.data.map((customer) => {
        if (customer.id === draggableId) {
          return updatedCustomer;
        }
        return customer;
      });

      updatedCustomers.sort((a, b) => {
        const dateA = new Date(a.updatedAt).getTime();
        const dateB = new Date(b.updatedAt).getTime();
        return dateB - dateA;
      });

      return {
        ...prevstate,
        data: updatedCustomers,
      };
    });

    createOrUpdateDocInFirestore(
      `/organization/${organization}/${COLLECTION_DATA.CUSTOMERS}/${draggableId}`,
      updatedCustomer,
    );
  };

  const renderCustomerCard = (customer) => {
    return (
      <Card hoverable onClick={() => handleCustomerClick(customer)}>
        <User user={getCustomerIdentity(customer)} />
        <Space wrap style={{ marginTop: "8px" }}>
          <Tooltip title="# of Conversations">
            <PhoneOutlined style={{ marginRight: "8px", color: "#52c41a" }} />
            {customer.conversations?.length || 0}
          </Tooltip>
          <Tooltip title="Updated At">
            <CalendarOutlined
              style={{ marginRight: "8px", color: "#52c41a" }}
            />
            {formatDateToHumanReadable(customer.updatedAt)}
          </Tooltip>
          {customer.company && (
            <Tooltip title="Company">
              <BankOutlined style={{ marginRight: "8px", color: "#52c41a" }} />
              {customer.company}
            </Tooltip>
          )}
          {customer.companyDomain && (
            <Tooltip title="Company Domain">
              <LinkOutlined style={{ marginRight: "8px", color: "#1890ff" }} />
              {customer.companyDomain}
            </Tooltip>
          )}
        </Space>
      </Card>
    );
  };

  return (
    <TableBoardDashboard
      tableConfig={tableConfig}
      handleSwitchChange={handleSwitchChange}
      loading={loading}
      columns={columns}
      loadMoreFunction={loadMoreCustomers}
      loadMorePerStage={loadMoreCustomersPerStage}
      onDragEnd={onDragEnd}
      data={customers}
      searchKey={"customer"}
      stageData={stageData}
      paginationPerStage={paginationPerStage}
      renderCustomerCard={renderCustomerCard}
      customFields={Object.values(customersCustomFields)}
      enableGrouping={true}
    />
  );
};
