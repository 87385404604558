import { Button, Tooltip } from "antd";
import { color } from "src/styles/variables";
import styled from "styled-components";

export const EmailFormContainer = styled.div`
  max-width: 85vw;
  margin: 0 auto;
  padding: 16px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const EmailHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const FromContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const AvatarContainer = styled.div`
  margin-right: 8px;
`;

export const EmailDetails = styled.div`
  font-size: 14px;
`;

export const StyledText = styled(Tooltip)`
  font-size: 14px;
  color: ${color.grayDark};
`;

export const SubmitButton = styled(Button)`
  //   width: 100%;
  margin-right: auto;
`;

export const SentEmailHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; /* Push content to opposite sides */
  margin-bottom: 20px;
`;

export const DateTime = styled.div`
  font-size: 12px;
  color: gray;
  white-space: nowrap; /* Prevent text wrapping */
  align-self: flex-start;
`;
