import React, { useEffect, useState } from "react";
import {
  Tabs,
  Card,
  Row,
  Col,
  Spin,
  Descriptions,
  Divider,
  DescriptionsProps,
  Tag,
} from "antd";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import {
  getRecordFromFireStore,
  getRecordsFromFireStore,
} from "src/firebaseAuth";
import { customerConverter, transcriptConverter } from "src/utils/converter";
import { FieldRenderer, TableBoardDashboard } from "src/components";
import { useOrganizationState } from "src/state/OrganizationState";
import { HoverContainer } from "../CustomersDashboard/Customers.styles";
import { COLLECTION_DATA, QUERY_PARAMS } from "src/utils/enums";
import { useModal } from "src/state/UseModal";
import {
  capitalizeFirstLetters,
  formatDateToHumanReadable,
  getColorForValue,
  getUsersEmailDictionary,
  humanize,
} from "src/utils/utils";
import { useUserState } from "src/state/UserState";
import { getCustomerIdentity } from "../CustomersDashboard/Customers.utils";
import { updateAccountInfo } from "./AccountsDashboard.utilis";
import { ConversationTable } from "../Dashboard/ConversationTable";
import { useNavigate } from "react-router-dom";
import { User } from "../User";
import { AccountAvatar } from "./AccountsDashboard";
import { InvoiceTable, Invoice } from "src/components/InvoicesDashboard";
import { useFetchInvoices } from "src/hooks/useFetchData";
import { useFetchAccount, useUpdateAccount } from "src/hooks/useAccount";
import { isDefaultCustomField } from "../Profile/Components/Settings/CustomField/CustomField.utils";

const { TabPane } = Tabs;

export const AccountModalContent = () => {
  const {
    organization,
    organizationSettings,
    evaluationForms,
    pendingCollaborators,
    collaborators,
    setAccounts,
    setCustomers: SetAllCustomers,
  } = useOrganizationState();
  const {
    data: invoices,
    loading: loadingInvoices,
    refetch,
  } = useFetchInvoices(organization);
  const updateAccount = useUpdateAccount();

  const [accountInfo, setAccountInfo] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [loadingCustomers, setLoadingCustomers] = useState(true);
  const [loadingConversations, setLoadingConversations] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const { accountsCustomFields, showRisk } = organizationSettings;
  const navigate = useNavigate();
  const allAccountsFields = Object.values(accountsCustomFields).reduce(
    (acc, field) => ({
      ...acc,
      [field.key]: {
        ...field,
        isDefault: isDefaultCustomField("accountsCustomFields", field.key),
      },
    }),
    {},
  );

  const { modalState } = useModal();
  const accountId = modalState.id;
  const { user } = useUserState();
  const {
    data: account,
    loading: loadingAccountInfo,
    refetch: refetchAccountInfo,
  } = useFetchAccount(organization, accountId);

  const accountInvoices = invoices?.data?.filter(
    (invoice) => invoice.accountId === accountId,
  ) as Invoice[];

  const customerColumns = [
    {
      accessorFn: (row) => getCustomerIdentity(row),
      enableEditing: false,
      header: "Customer Details",
      size: 200,
      Cell: ({ row }) => (
        <HoverContainer
          onClick={() =>
            navigate(
              `?customer=${encodeURIComponent(row.original.id)}&${QUERY_PARAMS.BREADCRUMB_TITLE}=${encodeURIComponent(row.original.customer)}`,
            )
          }
        >
          <User user={row.original} usePhoneIcon={true} />
        </HoverContainer>
      ),
    },
    {
      header: "Update At",
      enableEditing: false,
      accessorKey: "updatedAt", // accessor is the "key" in the data
      size: 150, // Adjust column width for better visibility
      Cell: ({ cell }) => {
        return formatDateToHumanReadable(cell.getValue());
      },
      enableColumnFilter: false,
    },
    {
      header: "# of Conversations",
      enableEditing: false,
      accessorKey: "conversations", // accessor is the "key" in the data
      Cell: ({ cell }) => {
        const conversations = cell.getValue();
        return conversations?.length;
      },
      size: 100, // Adjust column width for better visibility
    },
    {
      header: "Stage",
      accessorKey: "currentStage",
      enableEditing: false,
      Cell: ({ cell }) => {
        const stage = cell.getValue();
        return stage ? (
          <Tag color={getColorForValue(stage)}>
            {capitalizeFirstLetters(stage)}
          </Tag>
        ) : (
          ""
        );
      },
    },
  ];
  useEffect(() => {
    if (account) {
      setAccountInfo(account);
    }
  }, [account]);

  useEffect(() => {
    const fetchCustomers = async () => {
      setLoadingCustomers(true);
      const customerDocs = await getRecordsFromFireStore(
        `/organization/${organization}/${COLLECTION_DATA.CUSTOMERS}`,
        [
          {
            comparisonField: "accountId",
            comparisonOperator: "==",
            value: accountId,
          },
          {
            comparisonField: "updatedAt",
            order: "desc",
          },
        ],
        customerConverter,
      );
      setCustomers(customerDocs.data);
      setLoadingCustomers(false);
    };

    fetchCustomers();
  }, [accountId, organization]);

  useEffect(() => {
    if (customers.length > 0) {
      const fetchConversations = async () => {
        setLoadingConversations(true);

        const transcriptsDocsPromises = customers.flatMap((customer) =>
          customer.conversations?.map((conversationId) => {
            const decodedConversationId = decodeURIComponent(conversationId);
            return getRecordFromFireStore(
              `organization/${organization}/${COLLECTION_DATA.CONVERSATIONS}/${decodedConversationId}`,
              transcriptConverter(
                evaluationForms,
                getUsersEmailDictionary(collaborators, pendingCollaborators),
                user,
              ),
            ).then((docData) => {
              // Ensure the document includes its ID
              if (docData && !docData.id) {
                docData.id = decodedConversationId;
              }
              return docData;
            });
          }),
        );

        // Wait until all conversation documents are fetched and processed
        const transcriptsDocs = await Promise.all(transcriptsDocsPromises);
        // Filter out any null or undefined docs (e.g., deleted or missing docs)
        const validConversations = transcriptsDocs.filter(
          (conv) => conv && !conv.deleted,
        );
        const sortedConversations = validConversations.sort(
          (a: any, b: any) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        );

        setConversations(sortedConversations);
        setLoadingConversations(false);
      };

      fetchConversations();
    } else {
      setLoadingConversations(false);
    }
  }, [customers, organization]);

  const handleSave = async () => {
    setLoadingCustomers(true);
    const updatedAccount = {
      ...accountInfo,
    };
    await updateAccountInfo(
      organization,
      accountId,
      updatedAccount,
      updateAccount,
      setAccounts, // Update global state if necessary
      SetAllCustomers,
      customers,
    );

    refetchAccountInfo();
    setLoadingCustomers(false);

    setEditMode(false);
  };

  const getFieldValue = (accountInfo, field) => {
    if (!accountInfo) return null; // Early return if accountInfo is not available
    if (field.isDefault) {
      return accountInfo[field.key]; // Default fields
    } else {
      const customFields = accountInfo.customFields || {}; // Ensure fallback to an empty object
      return customFields[field.key] || null; // Custom fields
    }
  };

  const AccountDescriptions: DescriptionsProps["items"] = [
    {
      label: "Updated At",
      children: formatDateToHumanReadable(accountInfo?.updatedAt) || "",
    },
    ...Object.values(allAccountsFields).map((field: any) => ({
      label: humanize(field.key),
      children: (
        <FieldRenderer
          field={field}
          value={getFieldValue(accountInfo, field)}
          onChange={(key, value) => {
            if (field.isDefault) {
              // Update default fields
              setAccountInfo((prevState) => ({
                ...prevState,
                [key]: value,
              }));
            } else {
              // Update custom fields
              setAccountInfo((prevState) => ({
                ...prevState,
                customFields: {
                  ...prevState.customFields,
                  [key]: value,
                },
              }));
            }
          }}
          editMode={editMode && field.editable}
        />
      ),
    })),
  ];
  const handleTranscriptClick = async (transcript) => {
    navigate(`?conversation=${encodeURIComponent(transcript.id)}`);
  };
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <Spin spinning={loadingCustomers || loadingAccountInfo}>
      <Row gutter={16} style={{ padding: "20px" }}>
        <Col xs={24} md={7}>
          <Card title="Account">
            <AccountAvatar account={accountInfo} />
            <Divider />
            <Descriptions
              size={"small"}
              column={1}
              title="Details"
              items={AccountDescriptions}
              extra={
                editMode ? (
                  <SaveOutlined
                    style={{
                      marginLeft: "8px",
                    }}
                    onClick={handleSave}
                  />
                ) : (
                  <EditOutlined
                    style={{
                      marginLeft: "8px",
                    }}
                    onClick={toggleEditMode}
                  />
                )
              }
            />
          </Card>
        </Col>

        <Col xs={24} md={17}>
          <Card>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Customers" key="1">
                <TableBoardDashboard
                  columns={customerColumns}
                  data={{ data: customers, hasMore: false }}
                  searchKey="customer"
                />
              </TabPane>
              <TabPane tab="Conversations" key="2">
                {loadingConversations ? (
                  <Spin />
                ) : (
                  <ConversationTable
                    showRisk={showRisk}
                    data={conversations}
                    handleRowClick={handleTranscriptClick}
                    pageSize={4}
                  />
                )}
              </TabPane>

              <TabPane tab="Invoices" key="3">
                {loadingInvoices ? (
                  <Spin />
                ) : (
                  <InvoiceTable invoices={accountInvoices} />
                )}
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};
