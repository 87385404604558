import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Typography,
  message,
  List,
  Card,
  Popconfirm,
} from "antd";
import { PlusOutlined, CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  useGenerateApiKey,
  useListApiKeys,
  useDeactivateApiKey,
} from "src/hooks/usePostData";
import { useUserState } from "src/state/UserState";

const { Title, Text } = Typography;

const ApiKeyCard = () => {
  const { user } = useUserState();
  const generateApiKeyHook = useGenerateApiKey();
  const listApiKeysHook = useListApiKeys();
  const deactivateApiKeyHook = useDeactivateApiKey();
  const [apiKeys, setApiKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingKeys, setLoadingKeys] = useState(false);
  const [newApiKey, setNewApiKey] = useState("");
  useEffect(() => {
    fetchApiKeys();
  }, []);

  const fetchApiKeys = async () => {
    setLoadingKeys(true);
    try {
      const { data, loading, error } = await listApiKeysHook();
      if (error) {
        throw error;
      }
      if (!loading && data) {
        setApiKeys(data.apiKeys || []);
      }
    } catch (error) {
      console.error("Error fetching API keys:", error);
      message.error("Failed to fetch API keys");
    } finally {
      setLoadingKeys(false);
    }
  };

  const generateApiKey = async () => {
    setLoading(true);
    try {
      const { data, loading, error } = await generateApiKeyHook();
      if (error) {
        throw error;
      }
      if (!loading && data) {
        setNewApiKey(data.apiKey);
        message.success("API Key generated successfully!");
        fetchApiKeys(); // Refresh the list
      }
    } catch (error) {
      console.error("Error generating API Key:", error);
      message.error("An error occurred while generating the API Key.");
    } finally {
      setLoading(false);
    }
  };

  const deactivateApiKey = async (keyId) => {
    try {
      const { data, loading, error } = await deactivateApiKeyHook(keyId);
      if (error) {
        throw error;
      }

      if (!loading && data?.status === 200) {
        message.success("API Key deactivated successfully!");
        fetchApiKeys(); // Refresh the list
      }
    } catch (error) {
      console.error("Error deactivating API Key:", error);
      message.error("An error occurred while deactivating the API Key");
    }
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      message.success("Copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy text: ", err);
      message.error("Failed to copy to clipboard");
    }
  };

  return (
    <Card
      title={"API Keys"}
      extra={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={generateApiKey}
          loading={loading}
        >
          Generate New API Key
        </Button>
      }
    >
      {newApiKey && (
        <div
          style={{
            marginBottom: 24,
            background: "#f5f5f5",
            padding: 16,
            borderRadius: 4,
          }}
        >
          <Text strong>
            New API Key (copy it now, it won't be shown again):
          </Text>
          <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
            <Input.Password
              value={newApiKey}
              readOnly
              style={{ marginRight: 8 }}
            />
            <Button
              icon={<CopyOutlined />}
              onClick={() => copyToClipboard(newApiKey)}
            >
              Copy
            </Button>
          </div>
        </div>
      )}

      <List
        loading={loadingKeys}
        dataSource={apiKeys}
        renderItem={({ key, createdAt }) => (
          <List.Item
            style={{
              padding: "16px",
              background: "#fafafa",
              borderRadius: "8px",
              marginBottom: "8px",
            }}
            actions={[
              <Button.Group>
                <Popconfirm
                  title="Deactivate API Key"
                  description="Are you sure you want to deactivate this API key? This action cannot be undone."
                  onConfirm={() => deactivateApiKey(key)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button danger icon={<DeleteOutlined />} size="middle">
                    Deactivate
                  </Button>
                </Popconfirm>
              </Button.Group>,
            ]}
          >
            <List.Item.Meta
              title={
                <div style={{ marginBottom: "8px" }}>
                  <Text strong>Created: </Text>
                  <Text>{new Date(createdAt).toLocaleDateString()}</Text>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

export default ApiKeyCard;
