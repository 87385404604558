import React, { useMemo, useState } from "react";
import { Avatar, Input, List, message, Tooltip } from "antd";
import { CollaboratorAvatar } from "src/components/Global";
import { ParticipantsContainer } from "./AnalysisContent.styles";
import { useOrganizationState } from "src/state/OrganizationState";
import { useNavigate } from "react-router-dom";
import { getCustomerInfo } from "src/components/CustomersDashboard/Customers.utils";
import { color } from "src/styles/variables";
import {
  UserDeleteOutlined,
  UserAddOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { updateTranscriptToDb } from "../../Dashboard.utils";
import { ACCESS_TYPE, QUERY_PARAMS } from "src/utils/enums";
import { useUserState } from "src/state/UserState";
import { SaveIcon } from "../../Dashboard.styles";
import { emailOrPhone } from "src/utils/utils";

export const Participants = ({ modalData, setModalData }) => {
  const { participants } = modalData;
  const { collaborators, pendingCollaborators } = useOrganizationState();
  const [loading, setLoading] = useState(false);
  const [addCustomer, setAddCustomer] = useState(false);
  const [newCustomer, setNewCustomer] = useState("");

  const { user } = useUserState();

  const allInternalUsers = useMemo(
    () => [...(collaborators ?? []), ...(pendingCollaborators ?? [])],
    [collaborators, pendingCollaborators],
  );

  const { organization, customers } = useOrganizationState();

  const FetchCustomerID = async (customerValue) => {
    const customer = customers?.data?.find(
      (data) =>
        data.customer === customerValue ||
        data.phone === customerValue ||
        data.email === customerValue,
    );
    if (customer) {
      navigate(
        `?customer=${encodeURIComponent(customer.id)}&${QUERY_PARAMS.BREADCRUMB_TITLE}=${encodeURIComponent(customer.customer)}`,
      );
    } else {
      const customerData = await getCustomerInfo(customerValue, organization);
      navigate(
        `?customer=${encodeURIComponent(customerData.id)}&${QUERY_PARAMS.BREADCRUMB_TITLE}=${encodeURIComponent(customerData.customer)}`,
      );
    }
  };

  const handleNewCustomerOrStatusChange = async (
    participant,
    isCustomer,
    isNewCustomer = false,
  ) => {
    setLoading(true);
    const participantExists = participants.some(
      (p) =>
        (p.email && p.email === participant.email) ||
        (p.phone && p.phone === participant.phone) ||
        (p.customer && p.customer === participant.customer),
    );

    // If adding a new customer and they already exist, return an error
    if (isNewCustomer && participantExists) {
      setLoading(false);
      message.error("This participant already exists.");
      return;
    }

    let updatedParticipants;

    if (participantExists) {
      // Update the participant's isCustomer status
      updatedParticipants = participants.map((p) => {
        if (
          (p.email && p.email === participant.email) ||
          (p.phone && p.phone === participant.phone) ||
          (p.customer && p.customer === participant.customer)
        ) {
          return { ...p, isCustomer };
        }

        return p;
      });
    } else {
      // Add the new participant
      updatedParticipants = [
        ...participants,
        { ...participant, isCustomer, isExternal: !!isCustomer },
      ];
      console.log(
        `Adding new participant: ${participant.email || participant.phone || participant.customer}`,
      );
    }

    const updatedModalData = {
      ...modalData,
      participants: updatedParticipants,
    };
    setModalData(updatedModalData);
    const updates = {
      "metadata.participants": updatedParticipants,
    };

    try {
      await updateTranscriptToDb(organization, modalData.id, updates);
      message.success("Customer Added Successfully");
    } catch (error) {
      message.error("Failed to add customer. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  const handleSave = () => {
    setAddCustomer(false);
    if (newCustomer.trim()) {
      const customerType = emailOrPhone(newCustomer.trim());
      const participant = {
        [customerType]: newCustomer.trim(),
      };
      handleNewCustomerOrStatusChange(participant, true, true).catch(
        (error) => {
          console.error("An error occurred:", error); // Log any errors
        },
      );
      setNewCustomer("");
    }
  };

  const editPermission =
    modalData?.reviewer?.email === user?.email ||
    [ACCESS_TYPE.reviewer, ACCESS_TYPE.owner, ACCESS_TYPE.trucoAdmin].includes(
      user?.accessType,
    );

  const navigate = useNavigate();
  return (
    <List
      dataSource={
        editPermission
          ? [...participants, { isAddButton: true }] // Add a dummy item at the end if editPermission is true
          : [...participants] // Only display the team members if editPermission is false
      }
      loading={loading}
      renderItem={(participant: any, index) => {
        const isExternal = !allInternalUsers.find(
          (user) => user.email === participant?.email,
        );
        if (participant.isAddButton) {
          return (
            <List.Item onClick={() => setAddCustomer(true)}>
              <List.Item.Meta
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                avatar={
                  <Avatar
                    style={{
                      backgroundColor: color.orange,
                    }}
                    icon={<PlusOutlined />}
                  />
                }
                title={
                  addCustomer && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Input
                        placeholder="Enter email, phone, or customer ID"
                        value={newCustomer}
                        onChange={(e) => setNewCustomer(e.target.value)}
                        style={{ marginRight: "8px" }}
                        onPressEnter={handleSave} // Save on Enter key press
                      />
                      {newCustomer.trim() && (
                        <SaveIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleSave();
                          }}
                        />
                      )}
                    </div>
                  )
                }
                description={
                  !addCustomer && (
                    <span
                      style={{
                        color: color.orange,
                        padding: `0px 11px`,
                      }}
                    >
                      Add Customer
                    </span>
                  )
                }
              />
            </List.Item>
          );
        }

        return (
          <List.Item
            actions={[
              isExternal && (
                <Tooltip
                  title={
                    participant?.isCustomer
                      ? "Unset as customer"
                      : "Set as customer"
                  }
                  style={{
                    color: `${participant?.isCustomer ? color.red : "inherit"}`,
                    textDecoration: "underline",
                  }}
                >
                  {editPermission && (
                    <>
                      {participant?.isCustomer ? (
                        <UserDeleteOutlined
                          onClick={() =>
                            handleNewCustomerOrStatusChange(participant, false)
                          }
                          style={{
                            color: color.red,
                          }}
                        />
                      ) : (
                        <UserAddOutlined
                          onClick={() =>
                            handleNewCustomerOrStatusChange(participant, true)
                          }
                          style={{
                            color: color.green,
                          }}
                        />
                      )}
                    </>
                  )}
                </Tooltip>
              ),
            ]}
          >
            <List.Item.Meta
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              avatar={
                <CollaboratorAvatar key={index} collaborator={participant} />
              }
              title={
                <ParticipantsContainer
                  onClick={() => {
                    if (participant?.isCustomer) {
                      FetchCustomerID(participant?.email || participant?.phone);
                    } else if (!isExternal) {
                      navigate(
                        `?user=${encodeURIComponent(participant?.email)}`,
                      );
                    } else {
                      message.warning(
                        "This is not a customer or an Internal member",
                      );
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {participant?.name ||
                      participant?.email ||
                      participant?.phone ||
                      participant?.customer}
                  </div>
                </ParticipantsContainer>
              }
              description={
                participant?.isCustomer
                  ? "Customer"
                  : isExternal
                    ? "External"
                    : "Internal"
              }
            />
          </List.Item>
        );
      }}
    />
  );
};
