import React from "react";
import { Divider, Empty, Space } from "antd";
import { CollaboratorAvatar } from "src/components/Global";
import { elementSize } from "src/styles/variables";
import { DateTime, SentEmailHeader } from "../Email.styles";
import { extractEmail, removeQuotedReply } from "../EmailComponent.utils";

export const EmailMessage = ({ message, getCollaborator }) => {
  return (
    <div style={{ marginBottom: "20px" }}>
      <SentEmailHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CollaboratorAvatar
            collaborator={getCollaborator(extractEmail(message.from))}
          />
          <div style={{ marginLeft: "10px" }}>
            <div>
              <strong>{message.from}</strong>
            </div>
            <Space
              style={{
                color: "gray",
                marginTop: "4px", // Replace with `elementSize.sm`
              }}
            >
              <span>To: {message.to}</span>
            </Space>
          </div>
        </div>
        <DateTime>
          {message.date.split(" ")[0]} ·{" "}
          {message.date.split(" ").slice(1).join(" ")}
        </DateTime>
      </SentEmailHeader>

      <div
        dangerouslySetInnerHTML={{ __html: removeQuotedReply(message.body) }}
        style={{ marginTop: elementSize.md, marginLeft: elementSize.sm }}
      />
    </div>
  );
};

export const EmailBody = ({ thread, getCollaborator }) => {
  return (
    <div>
      {/* Main Email */}

      <EmailMessage message={thread} getCollaborator={getCollaborator} />

      <Divider />

      {thread.replies?.length ? (
        thread.replies.map((reply, idx) => (
          <React.Fragment key={idx}>
            <EmailMessage message={reply} getCollaborator={getCollaborator} />

            {idx < thread.replies.length - 1 && <Divider />}
          </React.Fragment>
        ))
      ) : (
        <Empty description=" No replies yet" />
      )}
    </div>
  );
};
