import { Form, Button, Modal } from "antd";
import { useCreateTask, useUpdateTask } from "src/hooks/useTask";
import { useOrganizationState } from "src/state/OrganizationState";
import { RenderFormFields } from "./TasksDashboard.utilis";
import { defaultOrganizationSettings } from "src/state/OrganizationState";
import { isDefaultCustomField } from "src/components/Profile/Components/Settings/CustomField/CustomField.utils";
import dayjs from "dayjs";

export const TaskModalContent = ({
  task = null,
  isModalVisible,
  setIsModalVisible,
  refetchTasks,
}) => {
  const { organization, organizationSettings } = useOrganizationState();
  const [form] = Form.useForm();
  const { tasksCustomFields } =
    organizationSettings || defaultOrganizationSettings;
  const createTask = useCreateTask();
  const updateTask = useUpdateTask();

  const allTaskFields = Object.values(tasksCustomFields).reduce(
    (acc, field) => ({
      ...acc,
      [field.key]: {
        ...field,
        isDefault: isDefaultCustomField("tasksCustomFields", field.key),
      },
    }),
    {},
  );

  // Function to handle the form submission
  const handleSubmit = async (values) => {
    // Extract custom fields dynamically
    const customFields = {};
    Object.values(allTaskFields).forEach((field: any) => {
      if (!field.isDefault) {
        customFields[field.key] = values[field.key];
        const { [field.key]: _, ...rest } = values;
        values = rest;
      }
    });
    if (task) {
      // Update existing task
      await updateTask(task.taskId, {
        ...values,
        organizationId: organization, // Attach organization ID
        customFields, // Add the custom fields
      });
    } else {
      // Create a new task
      await createTask({
        ...values,
        organizationId: organization, // Attach organization ID
        customFields, // Add the custom fields
      });
    }

    setIsModalVisible(false);
    form.resetFields();
    refetchTasks();
  };

  // Prepare initial values
  const initialValues = task
    ? {
        ...task,
        dueDate: task.dueDate ? dayjs(task.dueDate) : null, // Convert date to dayjs
        ...JSON.parse(task.customFields || "{}"), // Merge custom fields
      }
    : {};

  return (
    <Modal
      open={isModalVisible}
      title={task ? "Edit Task" : "Create a Task"}
      onCancel={() => setIsModalVisible(false)}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={initialValues}
      >
        {/* Render fields from tasksCustomFields */}
        {RenderFormFields(tasksCustomFields)}

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {task ? "Update Task" : "Create Task"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
