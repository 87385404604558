import { LOCALSTORAGE_TYPES } from "src/utils/enums";

import { Button, Space, message } from "antd";
import { useState } from "react";
import { humanize } from "src/utils/utils";
import { useDeleteTask, useFetchTasks, useUpdateTask } from "src/hooks/useTask";
import { useOrganizationState } from "src/state/OrganizationState";
import { TaskModalContent } from "./TasksModalContent";
import { DeleteWithPopConfirm } from "../Global";
import { EditOutlined } from "@ant-design/icons";
import { FieldRenderer, TableBoardDashboard } from "src/components";
import { spacing } from "src/styles/variables";
import { defaultOrganizationSettings } from "src/state/OrganizationState";
import { isDefaultCustomField } from "src/components/Profile/Components/Settings/CustomField/CustomField.utils";

export const TasksDashboard = () => {
  const { organization, organizationSettings } = useOrganizationState();
  const { tasksCustomFields } =
    organizationSettings || defaultOrganizationSettings;
  const {
    data: tasks,
    loading: loadingTasks,
    refetch,
  } = useFetchTasks(organization);

  const allTaskFields = Object.values(tasksCustomFields).map((field) => ({
    ...field,
    isDefault: isDefaultCustomField("tasksCustomFields", field.key),
  }));

  const deleteTask = useDeleteTask();
  const updateTask = useUpdateTask();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(false);

  const handleDeleteTask = async (task) => {
    await deleteTask(task?.taskId);
    refetch();
  };

  const handleSaveTask = async (taskId, field, value) => {
    try {
      const task = tasks?.data?.find((t) => t.taskId === taskId);
      if (!task) return;

      if (field.isDefault) {
        await updateTask(taskId, {
          [field.key]: field.type == "user" ? [value] : value,
        });
      } else {
        const customFields = JSON.parse(task.customFields || "{}");
        customFields[field.key] = value;
        await updateTask(taskId, {
          customFields: JSON.stringify(customFields),
        });
      }
      message.success("Task updated successfully");
      refetch();
    } catch (error) {
      message.error("Failed to update task");
      console.error("Error updating task:", error);
    }
  };

  const [tableConfig, setTableConfig] = useState(
    JSON.parse(
      localStorage.getItem(LOCALSTORAGE_TYPES.ACCOUNTS_TABLE_CONFIG),
    ) || {},
  );

  const getFieldValue = (cell, row, field) => {
    if (field.isDefault) {
      return row.original?.[field.key];
    } else {
      const customFields = JSON.parse(row.original.customFields || "{}");
      return customFields[field.key] || cell.getValue();
    }
  };
  const setFieldValue = (cell, row, field, value) => {
    if (field.isDefault) {
      row.original[field.key] = value;
    } else {
      const customFields = JSON.parse(row.original.customFields || "{}");
      customFields[field.key] = field.type == "user" ? [value] : value;
      row.original.customFields = JSON.stringify(customFields);
    }
  };

  const columns = [
    ...allTaskFields.map((field) => ({
      header: humanize(field.key),
      enableEditing: field.editable,
      size: field.type === "string" ? 200 : 100,
      Cell: ({ cell, row }) => {
        return (
          <FieldRenderer
            field={field}
            value={getFieldValue(cell, row, field)}
            editMode={false}
          />
        );
      },
      Edit: ({ cell, row, table }) => {
        const [inputValue, setInputValue] = useState(
          getFieldValue(cell, row, field),
        );
        return (
          <FieldRenderer
            field={field}
            value={inputValue}
            editMode={true}
            onChange={(key, value) => {
              setFieldValue(cell, row, field, value);
              setInputValue(value);
            }}
            onSave={(key, value) => {
              handleSaveTask(row.original.taskId, field, value);
            }}
          />
        );
      },
    })),
    {
      header: "Actions",
      accessorKey: "actions",
      size: 150,
      Cell: ({ row }) => {
        const task = row.original;

        return (
          <div style={{ display: "flex", gap: "8px" }}>
            {/* Edit Button */}
            <Button
              type="link"
              onClick={() => {
                setSelectedTask(task);
                setIsModalVisible(true);
              }}
              icon={<EditOutlined />}
            ></Button>
            {/* Delete Button */}
            <Space>
              {DeleteWithPopConfirm(
                "Delete Task",
                "Are you sure you want to delete this task?",
                (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleDeleteTask(task);
                },
              )}
            </Space>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Button
        onClick={() => setIsModalVisible(true)}
        style={{ marginBottom: spacing.lg }}
      >
        Create Task
      </Button>
      <TableBoardDashboard
        tableConfig={tableConfig}
        columns={columns}
        data={tasks}
        searchKey={"title"}
        customFields={Object.values(tasksCustomFields)}
        enableGrouping={false}
      />
      <TaskModalContent
        task={selectedTask}
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        refetchTasks={refetch}
      />
    </>
  );
};
