import {
  OutlookCalendarIcon,
  GoogleCalendarIcon,
  SlackColoredIcon,
  ZoomIcon,
  TalkdeskIcon,
  GoogleMeetsIcon,
  GoogleDriveIcon,
  OpenPhoneIcon,
  HubspotIcon,
  TeamsIcon,
  SalesforceIcon,
  GmailIcon,
} from "../../../Loading/icons";

export enum INTEGRATION_SCOPE {
  organizational = "organizational",
  user = "user",
}

export type Integration = {
  icon: JSX.Element;
  name: string;
  description: string;
  switchValue: boolean;
  locationHref?: any;
  connectComponent?: any;
  revoke: {
    url: string;
    successMsg: string;
    errorMsg: string;
  };
  fireBaseFieldId: string;
  setter: (value: any) => void;
  scope: INTEGRATION_SCOPE;
};

export const INTEGRATION_ICONS = {
  slack: <SlackColoredIcon />,
  googleCalendar: <GoogleCalendarIcon />,
  google_meet: <GoogleMeetsIcon />,
  zoom: <ZoomIcon />,
  outlookCalendar: <OutlookCalendarIcon />,
  talkdesk: <TalkdeskIcon />,
  gcs: <GoogleDriveIcon />,
  openphone: <OpenPhoneIcon />,
  hubspot: <HubspotIcon />,
  salesForce: <SalesforceIcon />,
  microsoft_teams: <TeamsIcon />,
  gmail: <GmailIcon />,
};

export const USER_INTEGRATIONS = [
  "googleCalendar",
  "zoom",
  "outlookCalendar",
  "gmail",
];
export const ORGANIZATIONAL_INTEGRATIONS = [
  "slack",
  "talkdesk",
  "openphone",
  "hubspot",
  "salesforce",
  "stripe",
];
export const EXCLUDED_SETTINGS = ["name"];

export const EXCLUDED_ORGANIZATIONAL_SETTINGS = [
  ...ORGANIZATIONAL_INTEGRATIONS,
  ...EXCLUDED_SETTINGS,
];
