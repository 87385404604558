import { useCallback, useMemo } from "react";
import { useFetchData } from "./useFetchData";
import usePostData from "./usePostData";

const BASE_URL = process.env.REACT_APP_TRUCO_BACKEND;

// TASK HOOKS

// Hook for fetching tasks
export const useFetchTasks = (organizationId) => {
  const params = useMemo(() => ({ organizationId }), [organizationId]);

  return useFetchData(`${BASE_URL}/tasks`, params, ["organizationId"]);
};

// Hook for creating a task
export const useCreateTask = () => {
  const url = `${BASE_URL}/tasks/create`;
  const { data, loading, error, postData } = usePostData(url);

  const createTask = async (input) => {
    const body = input;
    await postData(body); // No query params needed
    return { data, loading, error };
  };

  return createTask;
};

// Hook for updating a task
export const useUpdateTask = () => {
  const url = `${BASE_URL}/tasks/update`;
  const { data, loading, error, postData } = usePostData(url);

  const updateTask = useCallback(
    async (taskId, updates) => {
      // Pass updates as the body and taskId as query params
      await postData({ data: updates }, { taskId });
      return { data, loading, error };
    },
    [postData],
  );

  return updateTask;
};

// Hook for deleting a task
export const useDeleteTask = () => {
  const url = `${BASE_URL}/tasks/delete`;
  const { data, loading, error, postData } = usePostData(url);

  const deleteTask = useCallback(
    async (taskId) => {
      // Pass taskId as a query param, no body needed
      await postData({}, { taskId });
      return { data, loading, error };
    },
    [postData],
  );

  return deleteTask;
};
