import React from "react";
import { signOut } from "firebase/auth";
import { auth } from "src/firebaseAuth";
import {
  ArrowCircle,
  DesktopSignOutContainer,
  UserNavBarAvatar,
} from "./User.styles";
import { elementSize, color } from "src/styles/variables";
import { useUserState } from "src/state/UserState";
import { KeyboardArrowDown } from "@mui/icons-material";
import { CollaboratorAvatar } from "src/components";
import UnsubscribeManager from "src/utils/UnsubscribeManager";

export const handleSignOut = (navigate, setLogoutLoading) => {
  setLogoutLoading(true); // Stop loading regardless of the result
  UnsubscribeManager.getInstance().clearUnsubs(); // Clear all subscriptions
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      navigate && navigate("/");
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      alert(errorMessage);
    })
    .finally(() => {
      setLogoutLoading(false);
    });
};

export const DesktopSignOut = ({
  setOpenDesktopUserMenu,
  openDesktopUserMenu,
  headercolor,
}) => {
  const { user } = useUserState();

  return (
    <DesktopSignOutContainer
      onClick={() => setOpenDesktopUserMenu(!openDesktopUserMenu)}
    >
      <CollaboratorAvatar
        key={user?.name}
        collaborator={user}
        AvatarComponent={UserNavBarAvatar}
      />
      <ArrowCircle headercolor={headercolor}>
        <KeyboardArrowDown
          style={{
            fontSize: elementSize.md,
            color: color.black,
          }}
        />
      </ArrowCircle>
    </DesktopSignOutContainer>
  );
};
