import { unhumanize } from "src/utils/utils";
import {
  BaseCustomField,
  AccountsCustomFields,
  CustomersCustomFields,
  TasksCustomFields,
} from "./CustomField.types";

export const initialExistingCustomerCustomFields: BaseCustomField[] = [
  { fieldName: "First Name", key: "firstName", type: "string", editable: true },
  { fieldName: "Last Name", key: "lastName", type: "string", editable: true },
  { fieldName: "Address", key: "address", type: "string", editable: true },
  { fieldName: "Notes", key: "notes", type: "string", editable: true },
  {
    fieldName: "Customer Owner",
    key: "customerOwner",
    type: "user",
    editable: true,
  },
];
export const initialExistingAccontsCustomFields: BaseCustomField[] = [
  { fieldName: "Phone", key: "phone", type: "string", editable: true },
  { fieldName: "Notes", key: "notes", type: "string", editable: true },
];

export const initialExistingTasksCustomFields: BaseCustomField[] = [
  {
    fieldName: "Customers",
    key: "customers",
    type: "customer",
    editable: true,
  },
  { fieldName: "Accounts", key: "Accounts", type: "account", editable: true },
];

export const initialExistingConversationFields = [
  { fieldName: "Order Id", key: "order_id", type: "string", editable: true },
  { fieldName: "Customer", key: "customer", type: "string", editable: true },
];
export const defaultCustomerCustomFields: CustomersCustomFields = {
  email: {
    fieldName: "Email",
    key: "email",
    type: "string",
    editable: false,
  },
  phone: {
    fieldName: "Phone",
    key: "phone",
    type: "string",
    editable: false,
  },
  account: {
    fieldName: "Account",
    key: "account",
    type: "account",
    editable: false,
  },
  customerOwner: {
    fieldName: "Customer Owner",
    key: "customerOwner",
    type: "user",
    editable: true,
  },
  stage: {
    fieldName: "Stage",
    key: "stage",
    type: "select",
    editable: true,
    options: [
      "New",
      "Qualified",
      "Proposal",
      "Negotiation",
      "Closed Won",
      "Closed Lost",
    ],
  },
};
export const defaultAccountsCustomFields: AccountsCustomFields = {
  domain: {
    fieldName: "Domain",
    key: "domain",
    type: "string",
    editable: false,
  },
  updatedAt: {
    fieldName: "Updated At",
    key: "updatedAt",
    type: "timestamp",
    editable: false,
  },
  createdAt: {
    fieldName: "Created At",
    key: "createdAt",
    type: "timestamp",
    editable: false,
  },
  owner: {
    fieldName: "Owner",
    key: "owner",
    type: "user",
    editable: false,
  },
  stage: {
    fieldName: "Stage",
    key: "stage",
    type: "select",
    editable: true,
    options: [
      "New",
      "Qualified",
      "Proposal",
      "Negotiation",
      "Closed Won",
      "Closed Lost",
    ],
  },
};
export const defaultTasksCustomFields: TasksCustomFields = {
  title: {
    fieldName: "Title",
    key: "title",
    type: "string",
    editable: true,
  },
  description: {
    fieldName: "Description",
    key: "description",
    type: "string",
    editable: true,
  },
  assignee: {
    fieldName: "Assigned To",
    key: "assignee",
    type: "user",
    editable: true,
  },
  dueDate: {
    fieldName: "Due Date",
    key: "dueDate",
    type: "timestamp",
    editable: true,
  },
  status: {
    fieldName: "Status",
    key: "status",
    type: "select",
    editable: true,
    options: [
      "Not Started",
      "In Progress",
      "Completed",
      "On Hold",
      "Cancelled",
    ],
  },
  type: {
    fieldName: "Type",
    key: "type",
    type: "select",
    editable: true,
    options: ["Bug", "Feature", "Documentation", "Other"],
  },
};

export const trucoDataTypes = [
  { value: "string", label: "String" },
  { value: "number", label: "Number" },
  { value: "boolean", label: "CheckBox" },
  { value: "array", label: "Array" },
  { value: "timestamp", label: "Date" },
  { value: "currency", label: "Currency" },
  { value: "select", label: "Select" },
  { value: "user", label: "User" },
];

export const generateKey = (str) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

export const getKey = (modalType, fieldName) => {
  return modalType === "conversationCustomFields"
    ? unhumanize(fieldName)
    : generateKey(fieldName);
};

export const getModalTitle = (modalType) => {
  switch (modalType) {
    case "conversationCustomFields":
      return "Conversation Properties";
    case "customersCustomFields":
      return "Customer Properties";
    case "accountsCustomFields":
      return "Account Properties";
    case "tasksCustomFields":
      return "Task Properties";

    default:
      return "";
  }
};

export const getCustomFieldsKey = (modalType) => {
  switch (modalType) {
    case "conversationCustomFields":
      return "conversationCustomFields";
    case "customersCustomFields":
      return "customersCustomFields";
    case "accountsCustomFields":
      return "accountsCustomFields";
    case "tasksCustomFields":
      return "tasksCustomFields";
    default:
      return null;
  }
};

export const getDefaultFields = (modalType) => {
  switch (modalType) {
    case "conversationCustomFields":
      return [];
    case "customersCustomFields":
      return defaultCustomerCustomFields;
    case "accountsCustomFields":
      return defaultAccountsCustomFields;
    case "tasksCustomFields":
      return defaultTasksCustomFields;
    default:
      return [];
  }
};
export const getInitialExistingFields = (modalType) => {
  switch (modalType) {
    case "conversationCustomFields":
      return initialExistingConversationFields;
    case "customersCustomFields":
      return initialExistingCustomerCustomFields;
    case "accountsCustomFields":
      return initialExistingAccontsCustomFields;
    case "tasksCustomFields":
      return initialExistingTasksCustomFields;
    default:
      return null;
  }
};

export const isDefaultCustomField = (modalType, fieldKey) => {
  const defaultFields = getDefaultFields(modalType);
  if (Object.keys(defaultFields).includes(fieldKey)) return true;
  return false;
};
