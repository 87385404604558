import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { message, Space, Typography } from "antd";
import {
  sendSignInLinkToEmail,
  createUserWithEmailAndPassword,
  signInWithEmailLink,
  updateProfile,
  isSignInWithEmailLink,
  updatePassword,
} from "firebase/auth";
import {
  auth,
  getRecordFromFireStore,
  getRecordFromFireStoreWithQuery,
} from "src/firebaseAuth";
import { addUser } from "src/firebaseAuth";
import {
  Button,
  SignContainer,
  FormContainer,
  Form,
  PageWrapper,
  LogoTitle,
  SubTitleSignIn,
  CustomizedInput,
  CustomizedText,
} from "./User.styles";
import { GradientOrangeSpan } from "../LandingPage/LandingPage.styles";
import { color, spacing, fontSize } from "src/styles/variables";
import { useLocation } from "react-router-dom";
import { COLLECTION_DATA, QUERY_PARAMS } from "src/utils/enums";
import { decryptId, encryptId, modifyQueryParams } from "src/utils/utils";
import { updateUserDataBasedOnInvitation } from ".";
import { InvitedUser } from "src/utils/types";
import EmailNotification from "./EmailNotification";

export const SignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [timeLeft, setTimeLeft] = useState(120); // Countdown for resend
  const searchParams = new URLSearchParams(location.search);
  const invitationCode = searchParams.get(QUERY_PARAMS.INVITATION_CODE);
  const signWithEmailLink = isSignInWithEmailLink(auth, window.location.href);
  const [invitationData, setInvitationData] = useState(null as InvitedUser);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const mode = searchParams.get("mode");
  const modeSignIn = mode === "signIn";
  const apiKey = searchParams.get("apiKey");
  const encryptedEmail = searchParams.get("email");
  const encryptedPassword = searchParams.get("password");
  const showConfirmEmail =
    modeSignIn && apiKey && encryptedEmail && encryptedPassword;

  // Send sign-in link to email
  const registerWithEmailAndPassword = async (
    email: string,
    name: string,
    password: string,
  ) => {
    try {
      const encryptedEmail = encryptId(email);
      const encryptedPassword = encryptId(password);
      const actionCodeSettings = {
        url: `${window.location.origin}/signup?email=${encryptedEmail}&password=${encryptedPassword}`,
        handleCodeInApp: true,
      };

      await sendSignInLinkToEmail(auth, email, actionCodeSettings);

      navigate(`${location.pathname}`, {
        replace: true,
        state: { emailSent: true },
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const handleRegistrationWithInvitation = async (
    invitationData,
    email,
    password,
    name,
    invitationCode,
  ) => {
    const res = await createUserWithEmailAndPassword(auth, email, password);

    await updateProfile(res.user, {
      displayName: name,
    });

    // Update user data based on the invitation
    await updateUserDataBasedOnInvitation(invitationData, res, invitationCode);

    await addUser(res.user, "local");

    // Redirect user to the profile page after successful registration
    window.location.href = "/profile";
  };

  const register = async () => {
    try {
      // Check if invitationData exists
      if (invitationData) {
        await handleRegistrationWithInvitation(
          invitationData,
          email,
          password,
          name,
          invitationCode,
        );
      } else {
        const invitedButNotFromLinkData = await getRecordFromFireStoreWithQuery(
          `${COLLECTION_DATA.INVITATIONS}`,
          [
            {
              fieldPath: "email",
              opStr: "==",
              value: email, // Assuming `email` is the user-provided email
            },
          ],
        );

        // If invitedButNotFromLinkData exists, proceed similarly to the invitation-based registration
        if (
          invitedButNotFromLinkData &&
          Object.keys(invitedButNotFromLinkData).length > 0
        ) {
          console.group(invitedButNotFromLinkData);
          await handleRegistrationWithInvitation(
            invitedButNotFromLinkData,
            email,
            password,
            name,
            invitationCode,
          );
        } else {
          // If neither invitationData nor invitedButNotFromLinkData exists, proceed with regular registration
          await registerWithEmailAndPassword(email, name, password);
        }
      }
    } catch (err) {
      console.error("Error during registration:", err);
      alert(err.message);
    }
  };

  const handleKeypress = (e) => {
    var charCode = typeof e.which == "number" ? e.which : e.keyCode;
    if (charCode === 13) {
      register();
    }
  };

  // Fetch invitation data if available
  const fetchInvitationData = async () => {
    const data = await getRecordFromFireStore(
      `${COLLECTION_DATA.INVITATIONS}/${invitationCode}`,
    );
    setInvitationData(data);
    setEmail(data.email);
  };

  useEffect(() => {
    if (invitationCode && signWithEmailLink) {
      fetchInvitationData();
    }

    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [invitationCode]);

  const confirmAccount = async () => {
    const decryptedPassword = decryptId(encryptedPassword);
    const decryptedEmail = decryptId(encryptedEmail);

    if (decryptedPassword !== password) {
      message.error("Wrong Password");
      return;
    }
    // Sign in with the email link (using the oobCode from the URL)
    await signInWithEmailLink(auth, decryptedEmail, window.location.href)
      .then(async (res) => {
        await updatePassword(res.user, decryptedPassword);
        await updateProfile(res.user, { displayName: name });
        await updateUserDataBasedOnInvitation(
          invitationData,
          res,
          invitationCode,
        );
        await addUser(res.user, "local");
      })
      .catch((error) => {
        console.error("Error signing in with email link:", error);
        message.error("Failed to complete sign-up. Please try again.");
      });
  };

  const reSendEmail = () => {
    setTimeLeft(60);
    register(); // Resend the email
  };

  return (
    <PageWrapper>
      {location.state?.emailSent ? (
        <SignContainer>
          <EmailNotification
            messageText={`Please check the email address ${email} for instructions to complete sign-up.`}
            timeLeft={timeLeft}
            onResendEmail={reSendEmail}
          />
        </SignContainer>
      ) : isSignInWithEmailLink(auth, window.location.href) &&
        showConfirmEmail ? (
        <SignContainer>
          <LogoTitle>
            <GradientOrangeSpan>Truco</GradientOrangeSpan>
          </LogoTitle>
          <SubTitleSignIn>Confirm account</SubTitleSignIn>
          <FormContainer>
            <Form>
              <CustomizedInput
                id="email-address"
                name="email"
                type="email"
                placeholder="Email"
                required
                disabled={true}
                value={decryptId(encryptedEmail)}
              />
              <CustomizedInput
                id="user-name"
                name="name"
                type="text"
                placeholder="Name"
                maxLength="15"
                required
                onChange={(e) => setName(e.target.value)}
              />
              <CustomizedInput
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                required
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={handleKeypress}
              />
            </Form>

            <Button size={"fullWidth"} type="submit" onClick={confirmAccount}>
              Confirm Account
            </Button>
          </FormContainer>
        </SignContainer>
      ) : (
        <SignContainer>
          <LogoTitle>
            <GradientOrangeSpan>Truco</GradientOrangeSpan>
          </LogoTitle>
          <SubTitleSignIn>Create account</SubTitleSignIn>
          <FormContainer>
            <Form>
              <CustomizedInput
                id="email-address"
                name="email"
                type="email"
                placeholder="Email"
                required
                onChange={(e) => setEmail(e.target.value)}
                disabled={invitationData}
                value={email}
              />
              <CustomizedInput
                id="user-name"
                name="name"
                type="text"
                placeholder="Name"
                maxLength="15"
                required
                onChange={(e) => setName(e.target.value)}
              />
              <CustomizedInput
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                required
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={handleKeypress}
              />
              {invitationData && (
                <CustomizedInput
                  id="organization"
                  name="organization"
                  type="text"
                  placeholder="organization"
                  value={invitationData.organization}
                  disabled
                />
              )}
              <Space
                direction="horizontal"
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginTop: spacing.xs,
                }}
              >
                <CustomizedInput
                  id="terms-and-conditions"
                  name="checkbox"
                  type="checkbox"
                  required
                  onChange={(e) => setAgreedToTerms(e.target.checked)}
                />
                <Typography.Text
                  style={{ fontSize: fontSize.body, verticalAlign: "top" }}
                >
                  By signing in, I agree to Truco's{" "}
                  <Typography.Link
                    href="/terms"
                    style={{ fontSize: fontSize.body }}
                  >
                    Terms of Service
                  </Typography.Link>{" "}
                  and acknowledge I have read the{" "}
                  <Typography.Link
                    href="/privacy"
                    style={{ fontSize: fontSize.body }}
                  >
                    Privacy Policy
                  </Typography.Link>
                  .
                </Typography.Text>
              </Space>
            </Form>

            <Button
              size={"fullWidth"}
              type="submit"
              onClick={register}
              disabled={!agreedToTerms}
            >
              Sign Up
            </Button>

            <CustomizedText>
              Already have an account?{" "}
              <NavLink
                style={{ color: color.orange }}
                to={`/signin${modifyQueryParams(location.search)}`}
              >
                Sign in
              </NavLink>
            </CustomizedText>
          </FormContainer>
        </SignContainer>
      )}
    </PageWrapper>
  );
};
