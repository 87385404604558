import React, { useEffect, useState } from "react";
import { Card, Tooltip, Space, Avatar } from "antd";
import { BankOutlined } from "@ant-design/icons";
import {
  defaultOrganizationSettings,
  useOrganizationState,
} from "src/state/OrganizationState";
import { LOCALSTORAGE_TYPES, QUERY_PARAMS } from "src/utils/enums";

import { useNavigate } from "react-router-dom";
import { formatDateToHumanReadable, humanize } from "src/utils/utils";
import { UsergroupDeleteOutlined, CalendarOutlined } from "@ant-design/icons";

import { HoverContainer } from "../CustomersDashboard/Customers.styles";
import {
  getCustomerCountsByAccount,
  updateAccountInfo,
} from "./AccountsDashboard.utilis";
import { FieldRenderer, TableBoardDashboard } from "src/components";
import { Account } from "src/utils/types";
import { useFetchAccounts, useUpdateAccount } from "src/hooks/useAccount";
import { isDefaultCustomField } from "../Profile/Components/Settings/CustomField/CustomField.utils";

export const AccountAvatar = ({ account }) => {
  return account ? (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Avatar icon={<BankOutlined />} />
      <div style={{ marginLeft: "8px" }}>
        <Tooltip title={"Name"}>
          <div>{account.name}</div>
        </Tooltip>
        <Tooltip title={"Domain"}>
          <div style={{ fontSize: "12px", opacity: 0.7 }}>{account.domain}</div>
        </Tooltip>
      </div>
    </div>
  ) : (
    <></>
  );
};

export const AccountsDashboard = () => {
  const { organization, organizationSettings, accounts, setAccounts } =
    useOrganizationState();

  const { accountsCustomFields } =
    organizationSettings || defaultOrganizationSettings;

  const {
    data: fetchedAccounts,
    loading: loadingFetchAccount,
    refetch,
  } = useFetchAccounts(
    organization, // Replace with actual organization ID
    accounts.currentPage,
  );
  const [loading, setLoading] = useState(loadingFetchAccount);
  const { stages } = accountsCustomFields;
  const customerStages = stages?.options || [];

  const allAccountFields = Object.values(accountsCustomFields).map((field) => ({
    ...field,
    isDefault: isDefaultCustomField("accountsCustomFields", field.key),
  }));
  const updateAccount = useUpdateAccount();
  const navigate = useNavigate();

  const [stageData, setStageData] = useState({});
  const [tableConfig, setTableConfig] = useState(
    JSON.parse(
      localStorage.getItem(LOCALSTORAGE_TYPES.ACCOUNTS_TABLE_CONFIG),
    ) || {},
  );

  // Toggle drawer visibility
  const saveConfigToLocalStorage = (config) => {
    localStorage.setItem(
      LOCALSTORAGE_TYPES.ACCOUNTS_TABLE_CONFIG,
      JSON.stringify(config),
    );
    setTableConfig(config);
  };

  const getFieldValue = (cell, row, field) => {
    console.log("row.original.customFields", row.original.customFields);
    if (field.isDefault) {
      return row.original?.[field.key];
    } else {
      const customFields =
        typeof row.original.customFields === "string"
          ? JSON.parse(row.original.customFields || "{}")
          : row.original.customFields || {};

      return customFields[field.key] || cell.getValue();
    }
  };
  const setFieldValue = (cell, row, field, value) => {
    if (field.isDefault) {
      // Handle default fields
      row.original[field.key] = value;
    } else {
      try {
        // Parse `customFields` safely
        const customFields =
          typeof row.original.customFields === "string"
            ? JSON.parse(row.original.customFields || "{}")
            : row.original.customFields || {};

        // Assign the value to the specific field
        customFields[field.key] = field.type === "user" ? [value] : value;

        // Convert back to string and update the row
        // row.original.customFields = JSON.stringify(customFields);
      } catch (error) {
        console.error("Error parsing or updating customFields:", error);
        // Handle invalid JSON gracefully by resetting `customFields`
        row.original.customFields = JSON.stringify({ [field.key]: value });
      }
    }
  };

  // Handle switch change for each custom field
  const handleSwitchChange = (fieldKey, checked) => {
    const updatedConfig = { ...tableConfig, [fieldKey]: checked };
    saveConfigToLocalStorage(updatedConfig);
  };

  const handleSaveEditFieldInTable = async (row, key, value, field) => {
    if (field.isDefault) {
      // If it's a default field, update it directly
      row.original[key] = value;
      await updateAccountInfo(
        organization,
        row.original.accountId,
        { [key]: value },
        updateAccount, // Pass the updateAccount function here
        setAccounts,
        undefined,
        undefined,
        field,
      );
    } else {
      // If it's a custom field, update the `customFields` object
      const customFields = JSON.parse(row.original.customFields || "{}");
      customFields[key] = value; // Update the custom field value
      row.original.customFields = JSON.stringify(customFields);

      await updateAccountInfo(
        organization,
        row.original.accountId,
        { customFields: row.original.customFields }, // Save the updated customFields
        updateAccount, // Pass the updateAccount function here
        setAccounts,
        undefined,
        undefined,
        field,
      );
    }
    refetch();
  };

  const handleAccountClick = (account) => {
    navigate(
      `?account=${encodeURIComponent(account.accountId)}&${QUERY_PARAMS.BREADCRUMB_TITLE}=${encodeURIComponent(account.account)}`,
    );
  };

  useEffect(() => {
    if (!organizationSettings || !organization) return;
    if (accounts?.data) {
      updateStageData(accounts.data);
    }
  }, [organization, accounts?.data, organizationSettings]);

  useEffect(() => {
    const updateAccountsWithCounts = async () => {
      if (fetchedAccounts?.data) {
        setLoading(true);
        const { data: newAccounts, pagination } = fetchedAccounts;
        try {
          // Fetch customer counts for these accounts in a single step
          const customerCounts = await getCustomerCountsByAccount(
            organization,
            newAccounts.map((account) => account.accountId), // Map inline here
          );

          // Merge customer counts into the accounts directly
          const accountsWithCounts = newAccounts.map((account) => ({
            ...account,
            customerCount: customerCounts[account.accountId] || 0, // Direct merge
          }));

          // Update the state with customer counts included
          setAccounts((prev) => ({
            data:
              prev.currentPage === 1
                ? accountsWithCounts // Replace for the first page
                : [...prev.data, ...accountsWithCounts], // Append for subsequent pages
            hasMore: pagination.page < pagination.pageCount,
            currentPage: pagination.page,
            totalPages: pagination.pageCount,
          }));
          setLoading(false);
        } catch (error) {
          console.error("Error fetching customer counts:", error);
        }
      }
    };

    updateAccountsWithCounts();
  }, [fetchedAccounts]);

  const loadMoreAccounts = () => {
    if (accounts.hasMore && !loading) {
      setAccounts((prev) => ({
        ...prev,
        currentPage: prev.currentPage + 1, // Increment the page
      }));
    }
  };

  const columns = [
    {
      accessorFn: (row) => row.domain || row.name,
      enableEditing: false,
      header: "Account Identity",
      size: 200,
      Cell: ({ row }) => (
        <HoverContainer onClick={() => handleAccountClick(row.original)}>
          <AccountAvatar account={row.original} />
        </HoverContainer>
      ),
    },
    {
      header: "No. of Customers",
      enableEditing: false,
      accessorKey: "customerCount",
      Cell: ({ cell }) => {
        return cell.getValue();
      },
      size: 100,
    },
    {
      header: "No. of Invoices",
      enableEditing: false,
      accessorKey: "invoiceCount",
      Cell: ({ cell }) => {
        return cell.getValue();
      },
      size: 100,
    },
    ...Object.values(allAccountFields)
      .filter((field) => tableConfig[field.key] !== false)
      .map((field) => ({
        header: humanize(field.key),
        accessorKey: field.key,
        enableEditing: field.editable,
        size: field.type === "string" ? 200 : 100,
        Edit: ({ cell, row, table }) => {
          return (
            <FieldRenderer
              field={field}
              value={getFieldValue(cell, row, field)}
              editMode={true}
              isRowSetup={true}
              onChange={(key, value) => {
                setFieldValue(cell, row, field, value);
                // row.original[key] = value;
              }}
              onSave={async (key, value) => {
                // row.original[key] = value;
                setFieldValue(cell, row, field, value);
                // console.log("value", value);
                await handleSaveEditFieldInTable(row, key, value, field); // Call the helper function
                table.setEditingCell(null); // Reset editing state
              }}
            />
          );
        },
        Cell: ({ cell, row }) => {
          return (
            <Tooltip title="Double click to edit" arrow placement="top">
              <div style={{ cursor: "pointer" }}>
                <FieldRenderer
                  field={field}
                  value={getFieldValue(cell, row, field)}
                  editMode={false}
                  isRowSetup={true}
                  onChange={() => {}}
                />
              </div>
            </Tooltip>
          );
        },
      })),
  ];

  const [paginationPerStage, setPaginationPerStage] = useState({});

  const updateStageData = (accountData) => {
    const newStages = {
      "Unassigned Stage": [],
      ...customerStages
        ?.map((stage) => stage?.toLowerCase())
        .reduce((acc, stage) => {
          acc[stage] = [];
          return acc;
        }, {}),
    };
    accountData?.forEach((account) => {
      const stage = account?.currentStage?.toLowerCase() || "Unassigned Stage";
      if (newStages[stage]) {
        newStages[stage].push(account);
      }
    });

    setStageData(newStages);
    if (Object.keys(paginationPerStage).length === 0) {
      setPaginationPerStage((prevPagination) => {
        const updatedPagination = { ...prevPagination };

        Object.keys(newStages).forEach((stage) => {
          if (!updatedPagination[stage]) {
            updatedPagination[stage] = {
              hasMore: true,
              lastDoc: null,
            };
          }
        });
        return updatedPagination;
      });
    }
  };

  const renderAccountCard = (account: Account) => {
    return (
      <Card hoverable onClick={() => handleAccountClick(account)}>
        <Space direction="vertical">
          <Space>
            <Avatar icon={<BankOutlined />} />
            {account.account}
          </Space>
          <Space wrap style={{ marginTop: "8px" }}>
            <Tooltip title="# of Customers">
              <UsergroupDeleteOutlined
                style={{ marginRight: "8px", color: "#52c41a" }}
              />
              {account?.customerCounts || 0}
            </Tooltip>
            <Tooltip title="Updated At">
              <CalendarOutlined
                style={{ marginRight: "8px", color: "#52c41a" }}
              />
              {formatDateToHumanReadable(account.updatedAt)}
            </Tooltip>
          </Space>
        </Space>
      </Card>
    );
  };

  return (
    <TableBoardDashboard
      tableConfig={tableConfig}
      handleSwitchChange={handleSwitchChange}
      loading={loading || loadingFetchAccount}
      columns={columns}
      loadMoreFunction={loadMoreAccounts}
      data={accounts}
      searchKey={"account"}
      stageData={stageData}
      paginationPerStage={paginationPerStage}
      renderCustomerCard={renderAccountCard}
      customFields={Object.values(accountsCustomFields)}
    />
  );
};
