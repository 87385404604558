import { Button, Card, Col, Input, Row, Switch, Tooltip } from "antd";
import MaterialReactTable from "material-react-table";
import { useMemo, useState } from "react";
import { CustomersDrawerSettings } from "src/components/CustomersDashboard/CustomersDrawerSettings";
import { DraggableBoard } from "./DraggableBoard";
import { Loading } from "src/components/Loading";
import { SearchOutlined, ControlOutlined } from "@ant-design/icons";
import { color, elementSize } from "src/styles/variables";
import { BaseCustomField } from "../Profile/Components/Settings/CustomField/CustomField.types";
type TableBoardDashboardProps = {
  tableConfig?: Record<string, any>;
  handleSwitchChange?: any;
  loading?: boolean;
  columns?: any;
  loadMoreFunction?: () => void;
  loadMorePerStage?: (stage: string) => void;
  onDragEnd?: (result: any) => void;
  data: {
    data: Array<Record<string, any>>;
    hasMore: boolean;
  };
  searchKey: string;
  stageData?: any;
  paginationPerStage?: Record<string, number>;
  renderCustomerCard?: any;
  customFields?: BaseCustomField[];
  enableGrouping?: boolean;
};

export const TableBoardDashboard: React.FC<TableBoardDashboardProps> = ({
  tableConfig,
  handleSwitchChange,
  loading,
  columns,
  loadMoreFunction,
  loadMorePerStage,
  onDragEnd,
  data,
  searchKey,
  stageData,
  paginationPerStage,
  renderCustomerCard: renderCard,
  customFields,
  enableGrouping = false,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const [viewMode, setViewMode] = useState("table");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const toggleDrawer = () => setDrawerVisible(!drawerVisible);

  const filteredData = useMemo(() => {
    return data?.data?.filter((element) =>
      element?.[searchKey]?.toLowerCase()?.includes(searchTerm?.toLowerCase()),
    );
  }, [data?.data, searchTerm]);

  const groupedFilteredData = useMemo(() => {
    return (
      stageData &&
      Object.entries(stageData).reduce((acc, [stage, customers]: any) => {
        const filteredData = customers.filter((customer) =>
          customer?.[searchKey]
            ?.toLowerCase()
            .includes(searchTerm?.toLowerCase()),
        );

        if (filteredData.length > 0 && searchTerm) {
          acc[stage] = filteredData;
        }

        acc[stage] = filteredData;

        return acc;
      }, {})
    );
  }, [searchTerm, stageData]);

  return (
    <>
      {!Boolean(data?.data) ? (
        <Loading />
      ) : (
        <Card
          style={{
            padding: `${Boolean(tableConfig) ? `0px ${elementSize.sm}` : "0px"}`,
            border: !Boolean(tableConfig) && "none",
          }}
        >
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: 16 }}
          >
            <Col>
              <Input
                placeholder="Search ..."
                prefix={<SearchOutlined />}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  width: window.innerWidth < 576 ? "100%" : 300,
                  marginBottom: 8,
                }}
              />
            </Col>
            <Col>
              <Row
                align="middle"
                gutter={[0, 8]}
                style={{ textAlign: "center", gap: elementSize.sm }}
              >
                {onDragEnd && (
                  <Switch
                    checked={viewMode === "table"}
                    onChange={(checked) =>
                      setViewMode(checked ? "table" : "board")
                    }
                    checkedChildren="Table View"
                    unCheckedChildren="Board View"
                  />
                )}
                {tableConfig && (
                  <Tooltip title="Table Settings">
                    <ControlOutlined
                      rotate={90}
                      onClick={toggleDrawer}
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                        color: color.orange,
                      }}
                    />
                  </Tooltip>
                )}
              </Row>
            </Col>
          </Row>
          {tableConfig && (
            <CustomersDrawerSettings
              visible={drawerVisible}
              onClose={toggleDrawer}
              customersCustomFields={customFields}
              tableConfig={tableConfig}
              handleSwitchChange={handleSwitchChange}
              type={searchKey}
            />
          )}
          {viewMode === "table" ? (
            <>
              <MaterialReactTable
                columns={columns.map((column) => ({
                  ...column,
                  muiTableBodyCellProps: ({ cell }) => ({
                    sx: cell.column.columnDef.enableEditing
                      ? {
                          // Styles for editable cells
                          cursor: "pointer",
                          "&:hover": {
                            border: "1px solid gray",
                            backgroundColor: "lightgray",
                          },
                        }
                      : {
                          // Styles for non-editable cells
                          cursor: "default",
                          "&:hover": {
                            backgroundColor: "inherit",
                          },
                        },
                  }),
                }))}
                enableGrouping={enableGrouping}
                data={filteredData}
                enableStickyHeader={true}
                rowCount={filteredData?.length}
                enablePagination={false}
                renderTopToolbar={false}
                editingMode="cell"
                enableEditing={true}
                muiTableHeadCellProps={{
                  sx: {
                    fontWeight: 500,
                    fontSize: "13px",
                    color: "#6b7280",
                    backgroundColor: "transparent",
                    borderBottom: "none",
                    padding: "8px 16px",
                    textTransform: "uppercase",
                    letterSpacing: "0.05em",
                  },
                }}
                muiTableProps={{
                  sx: {
                    "& .MuiTableRow-root": {
                      marginBottom: "8px",
                      boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1)",
                      transition: "all 0.2s ease",
                      "&:hover": {
                        transform: "translateY(-1px)",
                        boxShadow: "0 2px 4px 0 rgb(0 0 0 / 0.1)",
                      },
                    },
                  },
                }}
                muiTablePaperProps={{
                  elevation: 0,
                  sx: {
                    boxShadow: "0",
                    height: "100%",
                  },
                }}
                muiTableBodyProps={{
                  sx: {
                    height: "100%",
                  },
                }}
              />
              {data?.hasMore && (
                <div style={{ textAlign: "center", marginTop: 16 }}>
                  <Button onClick={loadMoreFunction} loading={loading}>
                    Load More
                  </Button>
                </div>
              )}
            </>
          ) : (
            <DraggableBoard
              onDragEnd={onDragEnd}
              data={groupedFilteredData}
              renderItem={renderCard}
              onLoadMore={loadMorePerStage}
              paginationPerStage={paginationPerStage}
            />
          )}
        </Card>
      )}
    </>
  );
};
