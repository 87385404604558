import React, { useState } from "react";
import { createOrUpdateDocInFirestore, db } from "src/firebaseAuth";
import { useOrganizationState } from "src/state/OrganizationState";
import {
  Modal,
  Button,
  Breadcrumb,
  Input,
  List,
  message,
  Space,
  Checkbox,
  Form,
  Select,
  Tooltip,
  Card,
  Spin,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { spacing } from "src/styles/variables";
import {
  getCustomFieldsKey,
  getDefaultFields,
  getInitialExistingFields,
  getKey,
  getModalTitle,
  trucoDataTypes,
} from "./CustomField.utils";
import { AddRemoveTags } from "src/components/Global";
import { deleteField, doc, updateDoc } from "firebase/firestore";

export const CustomFieldModal = ({
  isModalVisible,
  handleCancel,
  modalType,
}) => {
  const { organization, organizationSettings, setOrganizationSettings } =
    useOrganizationState();
  const customFieldsKey = getCustomFieldsKey(modalType);
  const defaultFields = getDefaultFields(modalType);
  const customFields = organizationSettings?.[customFieldsKey] || defaultFields;

  const initialExistingFields = getInitialExistingFields(modalType);

  const [viewMode, setViewMode] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [fieldType, setFieldType] = useState("");
  const [options, setOptions] = useState([]); // Options for the "select" field
  const [editingField, setEditingField] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [disableEditingForDefault, setDisableEditingForDefault] =
    useState(false);

  const handleFieldsUpdate = async (newValue, keyToDelete = null) => {
    setIsLoading(true);
    try {
      setOrganizationSettings((prevSettings) => ({
        ...prevSettings,
        [customFieldsKey]: newValue,
      }));
      if (keyToDelete) {
        const docRef = doc(db, `/organization/${organization}`);
        await updateDoc(docRef, {
          [`${customFieldsKey}.${keyToDelete}`]: deleteField(),
        });
      } else {
        await createOrUpdateDocInFirestore(`/organization/${organization}`, {
          [customFieldsKey]: newValue,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleDeleteField = (key) => {
    if (defaultFields[key]) {
      message.error("Default fields cannot be removed");
      return;
    }
    const newFields = { ...customFields };

    delete newFields[key];

    handleFieldsUpdate(newFields, key);
  };

  const resetFields = () => {
    setFieldName("");
    setFieldType("");
    setOptions([]); // Reset options when creating new field
    setViewMode("");
    setDisableEditingForDefault(false);
  };

  const handleEditableChange = (key) => {
    if (!customFields[key]) return;

    const updatedFields = {
      ...customFields,
      [key]: {
        ...customFields[key],
        editable: !customFields[key].editable,
      },
    };
    handleFieldsUpdate(updatedFields);
  };

  const handleSaveEdit = () => {
    const key = getKey(modalType, fieldName);
    const updatedFields = {
      ...customFields,
      [key]: {
        ...customFields[editingField.key],
        fieldName,
        key,
        type: fieldType,
        ...(fieldType === "select" && options && options.length > 0
          ? { options }
          : { options: [] }),
      },
    };
    // If the key has changed, remove the old entry
    if (key !== editingField.key) {
      delete updatedFields[editingField.key];
    }
    handleFieldsUpdate(updatedFields);

    resetFields();
    setEditingField(null);
  };

  const handleAddField = (field) => {
    const key = field.key || getKey(modalType, field.fieldName);
    if (!customFields[key]) {
      handleFieldsUpdate({ ...customFields, [key]: { ...field, key } });
      message.success(`${field.fieldName} added.`);
    }
  };

  const handleCreateNewField = () => {
    const key = getKey(modalType, fieldName);
    const newField = {
      fieldName,
      key,
      type: fieldType,
      editable: true,
      ...(fieldType === "select" &&
        options &&
        options.length > 0 && { options }),
    };
    handleFieldsUpdate({ ...customFields, [key]: newField });
    resetFields();
    message.success(`${newField.fieldName} added successfully.`);
  };

  const isFieldAdded = (fieldName) => {
    const key = getKey(modalType, fieldName);
    return !!customFields[key];
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };
  const handleEditField = (field) => {
    setEditingField(field);
    setFieldName(field.fieldName);
    setFieldType(field.type);
    setOptions(field.options || []);
    setViewMode("edit");
  };
  // Filter fields based on the search query
  const filteredSelectedFields = Object.values(customFields).filter((field) =>
    field?.fieldName.toLowerCase().includes(searchQuery),
  );
  const filteredExistingFields = initialExistingFields.filter((field) =>
    field.fieldName.toLowerCase().includes(searchQuery),
  );
  const isFieldUnique = () => {
    const newKey = getKey(modalType, fieldName);
    const allFields = [
      ...Object.values(customFields),
      ...initialExistingFields,
      ...Object.values(defaultFields),
    ];

    return !allFields.some(
      (field) =>
        field.key === newKey &&
        (!editingField || field.key !== editingField.key),
    );
  };

  const canSave =
    fieldName !== "" &&
    fieldType !== "" &&
    (fieldType !== "select" || options.length >= 2) &&
    isFieldUnique;

  const saveTooltip = !isFieldUnique
    ? "This value already exists"
    : fieldType === "select" && options.length < 2
      ? "You need at least 2 options for select type"
      : "";

  return (
    <Modal
      open={isModalVisible}
      onCancel={() => {
        handleCancel();
        resetFields();
      }}
      footer={null}
      width={"70vw"}
      title={getModalTitle(modalType)}
    >
      <Spin spinning={isLoading}>
        {viewMode === "" && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: spacing.md,
              }}
            >
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search fields..."
                onChange={handleSearch}
                style={{ maxWidth: 300 }}
              />
              <Button
                type="primary"
                onClick={() => {
                  setViewMode("create");
                  setFieldName(""); // Reset field name on new creation
                  setFieldType(""); // Reset field type on new creation
                  setEditingField(null); // Reset editing field
                }}
              >
                + Create New
              </Button>
            </div>
            <Space
              direction="vertical"
              style={{ width: "100%", marginTop: spacing.lg }}
            >
              <Card>
                <List
                  dataSource={[
                    ...Object.values(defaultFields), // Ensure default fields are included first
                    ...filteredSelectedFields,
                    ...filteredExistingFields,
                  ].filter(
                    (item, index, self) =>
                      index ===
                      self.findIndex((t) => t.fieldName === item.fieldName),
                  )}
                  renderItem={(item) => {
                    const isDefaultField = Boolean(defaultFields?.[item.key]);

                    return (
                      <List.Item
                        actions={
                          isDefaultField
                            ? [
                                <Tooltip
                                  title={
                                    Boolean(item.editable)
                                      ? "These default values exist with the customer but values can be edited"
                                      : "These default values exist with the customer and can't be changed"
                                  }
                                >
                                  <Button
                                    type="text"
                                    disabled
                                    style={{ color: "gray" }}
                                  >
                                    Default Field
                                  </Button>
                                </Tooltip>,
                                Boolean(item.editable) && (
                                  <Tooltip title="Edit field">
                                    <Button
                                      type="text"
                                      icon={<EditOutlined />}
                                      onClick={() => {
                                        console.log("item", item);
                                        handleEditField(item);
                                        setDisableEditingForDefault(true);
                                      }}
                                    />
                                  </Tooltip>
                                ),
                              ]
                            : isFieldAdded(item.fieldName)
                              ? [
                                  <Tooltip title="Set if this field can be editable for every customer">
                                    <Checkbox
                                      checked={item.editable}
                                      onChange={() =>
                                        handleEditableChange(item.key)
                                      }
                                    >
                                      Can Be Updated
                                    </Checkbox>
                                  </Tooltip>,
                                  <Tooltip title="Edit field">
                                    <Button
                                      type="text"
                                      icon={<EditOutlined />}
                                      onClick={() => handleEditField(item)}
                                    />
                                  </Tooltip>,
                                  <Tooltip title="Delete field">
                                    <Button
                                      type="text"
                                      icon={<DeleteOutlined />}
                                      onClick={() =>
                                        handleDeleteField(item.key)
                                      }
                                    />
                                  </Tooltip>,
                                ]
                              : [
                                  <Tooltip title={"Add field to Customer"}>
                                    <Button
                                      type="text"
                                      icon={<PlusOutlined />}
                                      onClick={() => handleAddField(item)}
                                    />
                                  </Tooltip>,
                                ]
                        }
                        style={
                          isDefaultField && !Boolean(item.editable)
                            ? { opacity: 0.7, pointerEvents: "none" } // Apply opacity to dull the row and make it unclickable
                            : {}
                        }
                      >
                        <List.Item.Meta
                          title={item.fieldName}
                          description={item.type}
                        />
                      </List.Item>
                    );
                  }}
                />
              </Card>
            </Space>
          </>
        )}

        {(viewMode === "create" || viewMode === "edit") && (
          <>
            <Breadcrumb style={{ cursor: "pointer" }}>
              <Breadcrumb.Item
                onClick={() => {
                  setViewMode("");
                  setDisableEditingForDefault(false);
                }}
              >
                Customer Customized Field
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {viewMode === "edit" ? "Edit Field" : "Create New"}
              </Breadcrumb.Item>
            </Breadcrumb>

            {/* Form for creating/editing a field */}
            <Form layout="vertical" style={{ marginTop: 20 }}>
              {/* Field Name */}
              <Form.Item
                label="Field Name"
                required
                rules={[{ required: true, message: "Field Name is required" }]}
              >
                <Input
                  placeholder="Enter Field Name"
                  value={fieldName}
                  disabled={disableEditingForDefault}
                  onChange={(e) => setFieldName(e.target.value)}
                />
              </Form.Item>

              {/* Type */}
              <Form.Item
                label="Type"
                required
                rules={[{ required: true, message: "Type is required" }]}
              >
                <Select
                  placeholder="Select a type"
                  value={fieldType}
                  disabled={disableEditingForDefault}
                  onChange={(value) => setFieldType(value)}
                  options={trucoDataTypes}
                />
              </Form.Item>
              {fieldType === "select" && (
                <Form.Item label="Options">
                  <AddRemoveTags
                    tagType={"Options"}
                    tags={options}
                    onChange={(newItems) => setOptions(newItems)}
                    disabled={false}
                    style={{ justifyContent: "flex-end" }}
                  />
                </Form.Item>
              )}

              <Form.Item label="Description (Optional)">
                <Input.TextArea placeholder="Enter a description" />
              </Form.Item>

              <Tooltip title={saveTooltip}>
                <Button
                  type="primary"
                  onClick={
                    viewMode === "edit" ? handleSaveEdit : handleCreateNewField
                  }
                  disabled={!canSave}
                >
                  {viewMode === "edit" ? "Save Changes" : "Save"}
                </Button>
              </Tooltip>
            </Form>
          </>
        )}
      </Spin>
    </Modal>
  );
};
