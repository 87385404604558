import React from "react";
import { Tabs } from "antd";
import { EmailCompose } from "./EmailCompose";
import { EmailThreads } from "./EmailThreads/EmailThreads";

export const EmailTabComponent = () => {
  return (
    <Tabs defaultActiveKey="compose">
      <Tabs.TabPane tab="Compose Email" key="compose">
        <EmailCompose />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Email Threads" key="threads">
        <EmailThreads />
      </Tabs.TabPane>
    </Tabs>
  );
};
