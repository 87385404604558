import { doc, getDoc, increment, updateDoc } from "firebase/firestore";
import { db } from "src/firebaseAuth";

export const updateNumberOfSeats = async (
  queryPath,
  adjustmentValue,
  setOrganizationSettings,
) => {
  const docRef = doc(db, queryPath);

  try {
    // Fetch the document
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      // Document exists, get the current numberOfSeats
      const currentData = docSnapshot.data();
      const currentNumberOfSeats = currentData.numberOfSeats || 0;

      // Adjust the numberOfSeats
      const updatedNumberOfSeats = currentNumberOfSeats + adjustmentValue;

      // Update the document with the new numberOfSeats
      await updateDoc(docRef, { numberOfSeats: increment(adjustmentValue) });

      // Update the local state
      setOrganizationSettings((prevSettings) => ({
        ...prevSettings,
        numberOfSeats: updatedNumberOfSeats,
      }));
    } else {
      console.log(`No such document exists!`);
    }
  } catch (error) {
    console.error("Error fetching or updating document: ", error);
  }
};
