import { useCallback, useMemo } from "react";
import { useFetchData } from "./useFetchData";
import usePostData from "./usePostData";

const BASE_URL = process.env.REACT_APP_TRUCO_BACKEND;

// ACCOUNTS HOOKS

// Hook for fetching accounts
export const useFetchAccounts = (
  organizationId,
  page = 1,
  pageSize = 10,
  search = "",
) => {
  const params = useMemo(
    () => ({ organizationId, page, pageSize, search }),
    [organizationId, page, pageSize, search],
  );
  return useFetchData(`${BASE_URL}/accounts`, params, ["organizationId"]);
};

export const useCreateAccount = () => {
  const url = `${BASE_URL}/accounts/create`;
  const { postData } = usePostData(url);

  const createAccount = async (input) => {
    try {
      const { data } = await postData(input); // Call the API
      console.log(data);
      return data; // Return the API response directly
    } catch (error) {
      return {
        message: "Failed to create account",
        status: error.response?.status || 500,
        error,
      };
    }
  };

  return createAccount;
};

// Hook for updating an account
export const useUpdateAccount = () => {
  const url = `${BASE_URL}/accounts/update`;
  const { data, loading, error, postData } = usePostData(url);

  const updateAccount = useCallback(
    async (accountId, updates) => {
      // Pass updates as the body and taskId as query params
      await postData({ data: updates }, { accountId });
      return { data, loading, error };
    },
    [postData],
  );

  return updateAccount;
};
export const useFetchAccount = (organizationId, accountId) => {
  const params = useMemo(
    () => (organizationId && accountId ? { organizationId, accountId } : null),
    [organizationId, accountId],
  );

  return useFetchData(params ? `${BASE_URL}/account` : null, params);
};
