import {
  getRecordFromFireStoreWithQuery,
  createOrUpdateDocInFirestore,
} from "src/firebaseAuth";
import { customerConverter } from "src/utils/converter";
import { COLLECTION_DATA } from "src/utils/enums";

export const updateCustomerInfo = (
  organization,
  customerId,
  updatedCustomer,
  setCustomers = null,
  field?,
) => {
  try {
    if (
      field &&
      field.type === "timestamp" &&
      typeof updatedCustomer[field.key] === "object" &&
      Object.keys(updatedCustomer[field.key]).length === 0
    ) {
      return;
    }
    createOrUpdateDocInFirestore(
      `/organization/${organization}/${COLLECTION_DATA.CUSTOMERS}/${customerId}`,
      Object.fromEntries(
        Object.entries(updatedCustomer).filter(([_, v]) => v !== undefined),
      ),
    );

    if (setCustomers) {
      setCustomers((prevstate) => {
        return {
          ...prevstate,
          data: prevstate?.data?.map((customer) => {
            if (customer.id === customerId) {
              return { ...customer, ...updatedCustomer };
            }
            return customer;
          }),
        };
      });
    }
  } catch (error) {
    console.error("Error updating customer stage: ", error);
  }
};
export const getCustomerInfo = async (customerValue, organization) => {
  const customerData = await getRecordFromFireStoreWithQuery(
    `organization/${organization}/${COLLECTION_DATA.CUSTOMERS}`,
    [
      {
        fieldPath: "customer",
        opStr: "==",
        value: customerValue,
      },
      {
        fieldPath: "phone",
        opStr: "==",
        value: customerValue,
      },
      {
        fieldPath: "email",
        opStr: "==",
        value: customerValue,
      },
    ],
    customerConverter,
    null,
  );

  return customerData;
};

export const getCustomerIdentity = (customer) => {
  return {
    name: customer?.firstName
      ? `${customer?.firstName} ${customer?.lastName}`.trim()
      : customer?.name || "",
    email: customer?.email || customer?.phone || customer?.customer || "N/A",
  };
};

export const getDomainFromEmail = (email) => {
  const domainMatch = email?.match(/@(.+)$/);
  return domainMatch ? domainMatch[1] : "";
};
