export function hexToRgba(hex, opacity = 1) {
  let r, g, b;

  // If the hex value is in the shorthand form, expand it first (e.g., "#03f" -> "#0033ff")
  if (hex.length === 4) {
    r = "0x" + hex[1] + hex[1];
    g = "0x" + hex[2] + hex[2];
    b = "0x" + hex[3] + hex[3];
  } else {
    r = "0x" + hex[1] + hex[2];
    g = "0x" + hex[3] + hex[4];
    b = "0x" + hex[5] + hex[6];
  }

  return `rgba(${+r},${+g},${+b},${opacity})`;
}

export function hexToHex(hex, alpha = 1) {
  function blendWithWhite(rgb, alphaDecimal) {
    const white = 255; // White color component
    return Math.round(rgb * alphaDecimal + white * (1 - alphaDecimal));
  }

  const toHex = (component) => {
    const hex = component.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const r = parseInt(hex[1] + hex[2], 16);
  const g = parseInt(hex[3] + hex[4], 16);
  const b = parseInt(hex[5] + hex[6], 16);

  // Blend each component with white
  const blendedR = blendWithWhite(r, alpha);
  const blendedG = blendWithWhite(g, alpha);
  const blendedB = blendWithWhite(b, alpha);

  return `#${toHex(blendedR)}${toHex(blendedG)}${toHex(blendedB)}`;
}

export function assignColorsToValues(uniqueValues, defaultSaturation = "40%") {
  let colors = {};
  let hue = 0;
  const step = 360 / uniqueValues?.length; // Divide the color wheel into equal parts
  uniqueValues?.forEach((value) => {
    colors[value] = `hsl(${hue}, 100%, ${defaultSaturation})`;
    hue += step;
  });
  return colors;
}

export const maxWidth = "1500px";

export const spacing = {
  xxxs: "0.15rem",
  xxxss: "0.18rem",
  xxs: "0.25rem",
  xxss: "0.35rem",
  xss: "0.425rem",
  xs: "0.5rem",
  s: "0.625rem",
  sm: "0.75rem",
  sl: "0.875rem",
  md: "1rem",
  ml: "1.25rem",
  lg: "1.5rem",
  xl: "2rem",
  xll: "2.2rem",
  xxl: "3rem",
  xxll: "3.5rem",
  xxxl: "4rem",
  xxxll: "4.8rem",

  xxxxxll: "6.5rem",

  auto: "auto",
} as const;

export const borderRadius = {
  xxs: "4px",
  xxss: "5px",
  xs: "8px",
  sm: "12px",
  md: "16px",
  lg: "24px",
  xl: "48px",
  xxl: "64px",
} as const;

export const color = {
  // neutrals for script
  black: "#000000",
  blackMedium: "#393939",
  blackLight: "#4B4B4B",
  grayDark: "#525152",
  grayMedium: "#aca8a8",
  grayLight: "#f5f5f5",
  white: "#FFFFFF",
  gray: "#ccc",

  // brand colors
  orange: "#ff734d",
  olive: "#3e5b56",
  mediumGreen: "#8ba17f",
  lightGreen: "#4CBB17",
  lightOrange: "#fe9450",
  yellow: "#fcb74f",
  lightYellow: "#ffdc74",

  // notification colors
  red: "#cc0000",
  green: "#00cc00",
  blue: "#0000cc",
} as const;

export const grayBoxShadow = `  0 4px 20px ${hexToRgba(color.black, 0.1)}`;

export const fontSize = {
  small: "0.625rem",
  caption: "0.75rem",
  body: "0.875rem",
  bodyMl: "0.95rem",
  bodyLarge: "1rem",
  displayXL: "1.125rem",
  displayXLL: "1.275rem",
  displayXXL: "1.375rem",
  displayXXLL: "1.5rem",
  displayXXXL: "2rem",
  displayXXXLL: "2.5rem",
  displayXXXXL: "3rem",
  displayXXXXLL: "3.5rem",
  displayXXXXXL: "4rem",
  displayXXXXXXL: "5rem",
} as const;

export const fontWeight = {
  extraLight: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
} as const;

// consistent with https://ant.design/components/layout
export const deviceSize = {
  small_phone_size: "480px",
  regular_phone: "576px",
  large_phone: "768px",
  tablet: "1024px",
  xl: "1200px",
  xxl: "1600px",
} as const;

export const lineHeight = {
  xxxxs: "0.1rem",
  xxxs: "0.15rem",
  xxs: "0.25rem",
  xs: "0.5rem",
  sm: "0.75rem",
  md: "1rem",
  ml: "1.25rem",
  lg: "1.5rem",
  xl: "2rem",
  xxl: "3rem",
  xxxl: "3.5rem",
} as const;

export const elementSize = {
  xxs: "0.25rem",
  xs: "0.5rem",
  sm: "0.75rem",
  md: "1rem",
  ml: "1.25rem",
  lg: "1.5rem",
  xl: "2rem",
  xll: "2.25rem",
  xlll: "2.7rem",
  xxl: "3rem",
  xxll: "3.5rem",
  xxxl: "4rem",
  xxxxl: "5rem",
  xxxxxl: "6rem",
} as const;
