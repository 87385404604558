import { useEffect, useState } from "react";
import { getRecordFromFireStore } from "src/firebaseAuth";
import { useOrganizationState } from "src/state/OrganizationState";
import { COLLECTION_DATA } from "src/utils/enums";
import { customerConverter, transcriptConverter } from "src/utils/converter";
import {
  getUsersEmailDictionary,
  getColorForValue,
  humanize,
  formatDateToHumanReadable,
  capitalizeFirstLetters,
} from "src/utils/utils";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Row,
  Spin,
  Typography,
  Divider,
  Tag,
  Descriptions,
  Space,
} from "antd";
import type { DescriptionsProps } from "antd";

import { CollaboratorAvatar, FieldRenderer } from "src/components";
import { elementSize, spacing } from "src/styles/variables";
import { Customer } from "src/utils/types";
import { useUserState } from "src/state/UserState";
import { useNavigate } from "react-router-dom";
import { useModal } from "src/state/UseModal";
import { CustomerContainer, IntegrationContainer } from "./Customers.styles";
import { getCustomerIdentity, updateCustomerInfo } from "./Customers.utils";
import { ConversationTable } from "../Dashboard/ConversationTable";
import { IntegrationDataCard } from "../Dashboard/ConversationModal/ConversationModalBody/IntegrationsDataCard";
import AccountInfo from "./AccountInfo/AccountInfo";

export const CustomerModalContent = () => {
  const {
    organization,
    evaluationForms,
    collaborators,
    pendingCollaborators,
    organizationSettings,
    setCustomers,
  } = useOrganizationState();
  const { user } = useUserState();
  const { showRisk, customersCustomFields } = organizationSettings;
  const { stages } = customersCustomFields;
  const customerStages = stages?.options || [];

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>(null);
  const [customerTranscripts, setCustomerTranscripts] = useState(null);
  const [editMode, setEditMode] = useState<boolean>(false);

  const { modalState } = useModal();
  const customerId = modalState.id;

  useEffect(() => {
    if (user && organization && selectedCustomer?.conversations?.length > 0) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const transcriptsDocsPromises = selectedCustomer.conversations.map(
            (conversationId) => {
              const decodedConversationId = decodeURIComponent(conversationId);
              return getRecordFromFireStore(
                `organization/${organization}/${COLLECTION_DATA.CONVERSATIONS}/${decodedConversationId}`,
                transcriptConverter(
                  evaluationForms,
                  getUsersEmailDictionary(collaborators, pendingCollaborators),
                  user,
                ),
              ).then((docData) => {
                // Ensure the document includes its ID
                if (docData && !docData.id) {
                  docData.id = decodedConversationId;
                }
                return docData;
              });
            },
          );
          const transcriptsDocs = await Promise.all(transcriptsDocsPromises);
          const sortedTranscripts = transcriptsDocs
            .filter((conversation) => !!conversation)
            .sort((a, b) => {
              const dateA = new Date(a.created_at).getTime();
              const dateB = new Date(b.created_at).getTime();
              return dateB - dateA;
            });

          setCustomerTranscripts(sortedTranscripts);
        } catch (error) {
          console.error("Error fetching documents: ", error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [user, organization, selectedCustomer]);

  const getCustomerInfo = async () => {
    let customer;

    customer = await getRecordFromFireStore(
      `organization/${organization}/${COLLECTION_DATA.CUSTOMERS}/${customerId}`,
      customerConverter,
    );
    setSelectedCustomer(customer);
  };

  const assignStage = async (value) => {
    const updateData: any = {
      currentStage: value,
    };

    if (
      selectedCustomer.currentStage !== value &&
      selectedCustomer.currentStage
    ) {
      updateData.stageHistory = [
        {
          stage: selectedCustomer.currentStage,
          updatedBy: user.email,
          timestamp: new Date().toISOString(),
        },
        ...(selectedCustomer.stageHistory ?? []),
      ];
    }

    setSelectedCustomer((prevState) => {
      return {
        ...prevState,
        ...updateData,
      };
    });
  };

  const handleSave = async () => {
    const updatedCustomer = {
      ...selectedCustomer,
      updatedAt: new Date().toISOString(),
    };
    updateCustomerInfo(organization, customerId, updatedCustomer, setCustomers);
    toggleEditMode();
  };

  useEffect(() => {
    if (customerId) {
      getCustomerInfo();
    }
  }, [customerId, organization]);

  const handleTranscriptClick = async (transcript) => {
    navigate(`?conversation=${encodeURIComponent(transcript.id)}`);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const getCustomerDescriptions = (): DescriptionsProps["items"] => {
    return [
      {
        label: "Account",
        children: (
          <AccountInfo
            accountId={selectedCustomer.accountId}
            organization={organization}
            companyDomain={selectedCustomer.companyDomain}
            company={selectedCustomer.company}
            selectedCustomer={selectedCustomer}
            editMode={editMode}
          />
        ),
      },
      {
        key: "2",
        label: "Customer Stage",
        children: (
          <FieldRenderer
            field={{
              key: "currentStage",
              type: "select",
              options:
                customerStages?.map((option) => option.toLowerCase()) || [],
              label: "Stage",
              renderValue: (value) =>
                value ? (
                  <Tag
                    style={{ fontSize: "15px" }}
                    color={getColorForValue(value)}
                  >
                    {capitalizeFirstLetters(value)}
                  </Tag>
                ) : (
                  "None"
                ),
            }}
            value={selectedCustomer?.currentStage?.toLowerCase()}
            onChange={(key, value) => {
              assignStage(value);
            }}
            editMode={editMode}
          />
        ),
      },
      ...["email", "phone"].map((field) => ({
        label: humanize(field),
        children: selectedCustomer?.[field] || "",
      })),
      {
        label: "Updated At",
        children: formatDateToHumanReadable(selectedCustomer?.updatedAt) || "",
      },
      ...Object.values(customersCustomFields).map((field) => ({
        label: humanize(field.key),
        children: (
          <FieldRenderer
            field={field}
            value={selectedCustomer?.[field.key]}
            onChange={(key, value) =>
              setSelectedCustomer((prevState) => ({
                ...prevState,
                [key]: value,
              }))
            }
            editMode={editMode && field.editable}
          />
        ),
      })),
    ];
  };

  const fullCustomerIdentity = getCustomerIdentity(selectedCustomer);
  const customerIdentity =
    fullCustomerIdentity?.name || fullCustomerIdentity?.email;
  const integrationSources = [
    {
      source: "hubspot",
      data: selectedCustomer?.hubspot?.isArchived
        ? null
        : selectedCustomer?.hubspot,
    },
    {
      source: "salesForce",
      data: selectedCustomer?.salesforce,
    },
  ];

  return (
    <>
      {selectedCustomer && (
        <Spin spinning={!customerTranscripts && loading}>
          <Row gutter={[16, 32]} style={{ paddingTop: elementSize.md }}>
            <Col xl={8} xs={24} md={24}>
              <Card title="Customer">
                <Space size={"small"}>
                  <CollaboratorAvatar
                    collaborator={{ email: customerIdentity }}
                  />
                  <Typography.Title
                    level={5}
                    style={{
                      margin: 0,
                      alignItems: "center",
                    }}
                  >
                    {customerIdentity}
                  </Typography.Title>
                </Space>
                <Divider />

                <Descriptions
                  size={"small"}
                  column={1}
                  title="Details"
                  items={getCustomerDescriptions()}
                  extra={
                    editMode ? (
                      <SaveOutlined
                        style={{
                          marginLeft: "8px",
                        }}
                        onClick={handleSave}
                      />
                    ) : (
                      <EditOutlined
                        style={{
                          marginLeft: "8px",
                        }}
                        onClick={toggleEditMode}
                      />
                    )
                  }
                />

                <IntegrationContainer>
                  {integrationSources.map(
                    ({ source, data }) =>
                      data && (
                        <IntegrationDataCard
                          key={source}
                          modalData={{
                            file_source: source,
                            integration_data: { ...data },
                          }}
                        />
                      ),
                  )}
                </IntegrationContainer>
              </Card>
            </Col>

            <Col xl={16} xs={24} md={24}>
              <Card title={`${customerTranscripts?.length || 0} Conversations`}>
                <div
                  style={{
                    maxHeight: "400px",
                    overflowY: "auto",
                  }}
                >
                  {customerTranscripts && (
                    <ConversationTable
                      showRisk={showRisk}
                      data={customerTranscripts}
                      handleRowClick={handleTranscriptClick}
                      pageSize={5}
                    />
                  )}
                </div>
              </Card>
              {customerTranscripts?.length > 0 && (
                <>
                  {/* <Divider /> */}
                  <Card style={{ marginTop: elementSize.ml }}>
                    <CustomerContainer>
                      <Typography.Title
                        level={5}
                        style={{ marginTop: 0, marginBottom: spacing.md }}
                      >
                        Latest Conversation Summary:
                      </Typography.Title>
                    </CustomerContainer>
                    <Typography.Text>
                      {
                        customerTranscripts[customerTranscripts?.length - 1]
                          ?.summary
                      }
                    </Typography.Text>
                  </Card>
                </>
              )}
            </Col>
          </Row>
        </Spin>
      )}
    </>
  );
};
