import { useState } from "react";
import { Button, Space, Tooltip, Tag } from "antd";
import { BankOutlined, LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { color } from "src/styles/variables";
import { AccountSetupModal } from "./AccountSetupModal";
import { useFetchAccount } from "src/hooks/useAccount";

const AccountInfo = ({
  accountId,
  organization,
  companyDomain,
  company,
  selectedCustomer,
  editMode = false,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const { data: customerAccount, loading: loadingAccountInfo } =
    useFetchAccount(accountId ? organization : null, accountId);

  if (loadingAccountInfo) {
    return <LoadingOutlined style={{ paddingLeft: "8px" }} />;
  }
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {accountId ? (
        <>
          <Tooltip title={editMode ? "Edit Account" : "Go to Account"}>
            <Tag
              onClick={() => {
                editMode
                  ? setIsModalVisible(true)
                  : navigate(`/accounts?account=${accountId}`);
              }}
              style={{ cursor: "pointer" }}
            >
              <BankOutlined
                style={{ marginRight: "4px", color: color.orange }}
              />
              {editMode && "Change "}
              {customerAccount?.name ||
                customerAccount?.domain ||
                companyDomain ||
                company}
            </Tag>
          </Tooltip>
        </>
      ) : (
        <Space direction="vertical" size={3}>
          <Button onClick={() => setIsModalVisible(true)}>
            <BankOutlined style={{ marginRight: "4px", color: color.orange }} />
            {"Add Account"}
          </Button>
        </Space>
      )}

      {isModalVisible && (
        <AccountSetupModal
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          selectedCustomer={selectedCustomer}
          companyDomain={companyDomain}
        />
      )}
    </div>
  );
};

export default AccountInfo;
