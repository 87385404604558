import {
  Modal,
  Radio,
  Select,
  Form,
  Input,
  Alert,
  message,
  Spin,
  Typography,
} from "antd";
import { useState } from "react";
import {
  getRecordsFromFireStore,
  updateDocInFireStore,
} from "src/firebaseAuth";
import { useOrganizationState } from "src/state/OrganizationState";
import { COLLECTION_DATA } from "src/utils/enums";
import { getDomainFromEmail } from "../Customers.utils";
import { color, spacing } from "src/styles/variables";
import { useCreateAccount, useFetchAccounts } from "src/hooks/useAccount";

export const AccountSetupModal = ({
  visible,
  onClose,
  selectedCustomer,
  companyDomain,
}) => {
  const [accountType, setAccountType] = useState("existing");
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [newAccount, setNewAccount] = useState({
    domain: companyDomain || getDomainFromEmail(selectedCustomer.email) || "",
    name: "",
  });
  const createAccount = useCreateAccount();

  const [modalLoading, setModalLoading] = useState(false);
  const { organization } = useOrganizationState();
  const isFormComplete = () => {
    if (accountType === "existing") {
      return selectedAccountId !== null;
    } else {
      return newAccount.domain && newAccount.name;
    }
  };
  const {
    data: accounts,
    loading: loadingAccounts,
    refetch,
  } = useFetchAccounts(
    organization, // Replace with actual organization ID
    1,
    50,
  );

  const fetchCustomersByDomain = async (domain) => {
    try {
      const { data } = await getRecordsFromFireStore(
        `/organization/${organization}/${COLLECTION_DATA.CUSTOMERS}`,
        [
          {
            comparisonField: "companyDomain",
            comparisonOperator: "==",
            value: domain,
          },
        ],
      );

      return data;
    } catch (error) {
      console.error("Error fetching customers by domain:", error);
      return [];
    }
  };

  const handleAccountTypeChange = (e) => {
    const selectedType = e.target.value;
    setAccountType(selectedType);
    setSelectedAccountId(null);
    setNewAccount({ ...newAccount, name: "" });
  };

  const handleSave = async () => {
    setModalLoading(true);
    try {
      let accountId = selectedAccountId;

      if (accountType === "new") {
        const newAccountData = {
          domain: newAccount.domain,
          name: newAccount.name,
          organizationId: organization,
        };

        const response = await createAccount(newAccountData);
        if (response.status !== 200) {
          // Handle backend error response
          message.error(response?.message || "Failed to create account.");
          setModalLoading(false);
          return;
        }

        accountId = response.accountId; // Extract accountId from the response

        // Step 2: Fetch all customers with the matching companyDomain
        let matchingCustomers = await fetchCustomersByDomain(newAccount.domain);

        const isSelectedCustomerIncluded = matchingCustomers.some(
          (customer) => customer.id === selectedCustomer.id,
        );

        // If not included, add selectedCustomer to matchingCustomers
        if (!isSelectedCustomerIncluded) {
          matchingCustomers = [...matchingCustomers, selectedCustomer];
        }

        // Step 3: Update each matching customer with the new accountId
        await Promise.all(
          matchingCustomers.map(async (customer: any) => {
            let updateData = { accountId, updatedAt: new Date().toISOString() };

            // Only update companyDomain if newAccount.domain differs from the existing companyDomain
            if (
              newAccount.domain &&
              newAccount.domain !== customer.companyDomain
            ) {
              updateData["companyDomain"] = newAccount.domain;
            }
            if (
              newAccount?.name &&
              newAccount.name !== selectedCustomer.company
            ) {
              updateData["company"] = newAccount.name;
            }
            await updateDocInFireStore(
              `/organization/${organization}/${COLLECTION_DATA.CUSTOMERS}/${customer.id}`,
              updateData,
            );
          }),
        );
      } else {
        // Fetch domain of selected existing account (if using an existing account)
        const selectedAccount = accounts?.data?.find(
          (account) => account.accountId === selectedAccountId,
        );
        let domain = selectedAccount?.domain;

        const updateData = { accountId, updatedAt: new Date().toISOString() };

        // Only update companyDomain if domain differs from selectedCustomer's companyDomain
        if (domain && domain !== selectedCustomer.companyDomain) {
          updateData["companyDomain"] = domain;
        }
        if (
          selectedAccount?.name &&
          selectedAccount?.name !== selectedCustomer.company
        ) {
          updateData["company"] = selectedAccount.name;
        }
        await updateDocInFireStore(
          `/organization/${organization}/${COLLECTION_DATA.CUSTOMERS}/${selectedCustomer.id}`,
          updateData,
        );
      }

      onClose(); // Close modal after save
      setModalLoading(false); // Always ensure loading is stopped
      window.location.reload(); // Only reload after successful save
    } catch (error) {
      console.error("Error saving account information:", error);
      setModalLoading(false); // Always ensure loading is stopped
      window.location.reload(); // Only reload after successful save
    }
  };

  return (
    <Modal
      visible={visible}
      title="Select or Create an Account"
      onCancel={onClose}
      onOk={handleSave}
      confirmLoading={modalLoading}
      okButtonProps={{ disabled: !isFormComplete() }} // Disable OK button if form is incomplete
    >
      <Radio.Group onChange={handleAccountTypeChange} value={accountType}>
        <Radio value="existing">Use Existing Account</Radio>
        <Radio value="new">Create New Account</Radio>
      </Radio.Group>

      {accountType === "existing" ? (
        <Select
          placeholder="Select an account"
          style={{ width: "100%", marginTop: 16 }}
          onChange={setSelectedAccountId}
          notFoundContent={
            loadingAccounts ? <Spin size="small" /> : "No accounts found"
          }
        >
          {accounts?.data?.map((account) => (
            <Select.Option key={account.accountId} value={account.accountId}>
              {account.name}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <Form layout="vertical" style={{ marginTop: 16 }}>
          <Form.Item label="Domain" rules={[{ required: true }]}>
            <Input
              value={newAccount.domain}
              onChange={(e) =>
                setNewAccount({ ...newAccount, domain: e.target.value })
              }
              placeholder="e.g., example.com"
            />
            <Typography.Text
              type="secondary"
              style={{
                display: "block",
                color: color.lightOrange,
              }}
            >
              Enter the domain associated with this account (e.g., company.com).
            </Typography.Text>
          </Form.Item>
          <Form.Item label="Account Name" rules={[{ required: true }]}>
            <Input
              value={newAccount.name}
              onChange={(e) =>
                setNewAccount({ ...newAccount, name: e.target.value })
              }
              placeholder="Enter account name"
            />
          </Form.Item>
          <Alert
            message="Note: All customers with the same domain will be automatically added to this account."
            type="warning"
            showIcon
            style={{ marginBottom: spacing.lg }}
          />
        </Form>
      )}
    </Modal>
  );
};
