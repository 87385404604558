import React, { useState } from "react";
import { Form, Input, message } from "antd";
import axios from "axios";
import { CollaboratorAvatar } from "../Global";
import { useUserState } from "src/state/UserState";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { cleanBody } from "./EmailComponent.utils";
import {
  EmailFormContainer,
  FromContainer,
  AvatarContainer,
  SubmitButton,
  EmailDetails,
  StyledText,
} from "./Email.styles";
import { elementSize } from "src/styles/variables";

// Styled components for layout

export const EmailCompose = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useUserState(); // Assume user contains email and avatar details
  const [form] = Form.useForm(); // Create a form instance

  const handleSubmit = async (values) => {
    setLoading(true);
    const sanitizedBody = cleanBody(values.body);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TRUCO_BACKEND}/send-email`,
        {
          user_email: user.email, // The user's email
          to: values.to,
          subject: values.subject,
          body: sanitizedBody,
        },
      );
      message.success(response.data.status || "Email sent successfully!");
      form.resetFields();
    } catch (error) {
      message.error(
        error.response?.data?.error ||
          "Failed to send email. Please try again.",
      );
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <EmailFormContainer>
      {/* <EmailHeader>
        <h3>Compose Email</h3>
      </EmailHeader> */}
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <FromContainer>
          <AvatarContainer>
            <CollaboratorAvatar collaborator={user} />
          </AvatarContainer>
          <EmailDetails>
            <div>From</div>
            <StyledText title="This is your sending email">
              <div style={{ fontSize: "12px", opacity: 0.7 }}>{user.email}</div>
            </StyledText>
          </EmailDetails>
        </FromContainer>

        {/* To Field */}
        <Form.Item
          label="To"
          name="to"
          rules={[
            { required: true, message: "Please enter the recipient's email" },
          ]}
        >
          <Input placeholder="Recipient's Email" />
        </Form.Item>

        <Form.Item
          label="Subject"
          name="subject"
          rules={[
            { required: true, message: "Please enter the email subject" },
          ]}
        >
          <Input placeholder="Email Subject" />
        </Form.Item>

        <Form.Item
          label="Body"
          name="body"
          rules={[{ required: true, message: "Please enter the email body" }]}
        >
          <ReactQuill
            placeholder="Write your email body here..."
            theme="snow"
            style={{
              minHeight: "100px",
              marginBottom: elementSize.md,
            }} // Adjust the height to make it bigger
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link", "code-block"],
              ],
            }}
          />
        </Form.Item>
        <Form.Item style={{ textAlign: "end" }}>
          <SubmitButton type="primary" htmlType="submit" loading={loading}>
            Send Email
          </SubmitButton>
        </Form.Item>
      </Form>
    </EmailFormContainer>
  );
};
