import { PLAYLIST_TYPE } from "src/utils/enums";
import { v4 as uuidv4 } from "uuid";
import { message } from "antd";
import { getRecordFromFireStore } from "src/firebaseAuth";
import { updateTranscriptToDb } from "src/components/Dashboard/Dashboard.utils";

import { addPlaylistToTranscript } from "./Playlist.utils";
import { COLLECTION_DATA } from "src/utils/enums";
import { addOrUpdateClip } from "src/apis/clipsService";

export const addClipToPlaylist = (
  organization,
  conversationId: string,
  playlistId: string,
  playlistTitle: string,
  clip: any,
) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const uniqueId = uuidv4();
      const newClip = { ...clip, id: uniqueId };
      const createdAt = new Date().toISOString();
      await addOrUpdateClip(
        uniqueId,
        organization,
        conversationId,
        playlistId,
        playlistTitle,
        createdAt,
        newClip,
      );
      await addPlaylistToTranscript(organization, clip.conversationId, {
        id: playlistId,
        title: playlistTitle,
        type: PLAYLIST_TYPE.STANDARD,
        summary: clip.summary,
        clipId: uniqueId,
      });
      message.success("Clip added successfully to the playlist");
      resolve();
    } catch (error) {
      console.error("Error adding clip to playlist: ", error);
      message.error("Failed to add clip to playlist. Please try again.");
      reject(error);
    }
  });
};

export const updateClipSummaryInPlaylist = async (
  organization,
  playlistId,
  conversationId,
  clipId,
  editedSummary,
) => {
  if (!editedSummary || !clipId) return;
  addOrUpdateClip(
    clipId,
    organization,
    conversationId,
    playlistId,
    null,
    null,
    {
      summary: editedSummary,
    },
  );

  const conversationRefPath = `/organization/${organization}/${COLLECTION_DATA.CONVERSATIONS}/${conversationId}`;
  const conversation = await getRecordFromFireStore(conversationRefPath);

  const updatedPlaylists = conversation.playlists?.map((playlist) =>
    playlist.id === playlistId && playlist.clipId === clipId
      ? {
          ...playlist,
          summary: editedSummary,
          original_summary: playlist?.original_summary || playlist?.summary,
        }
      : playlist,
  );

  const updates = {
    playlists: updatedPlaylists,
  };

  updateTranscriptToDb(organization, conversationId, updates);
};
