import React from "react";
import { useNavigate } from "react-router-dom";
import { Table, Tag, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Invoice } from "./Invoice.types";

interface InvoiceTableProps {
  invoices?: Invoice[];
  loading?: boolean;
}

export const InvoiceTable: React.FC<InvoiceTableProps> = ({
  invoices,
  loading = false,
}) => {
  const navigate = useNavigate();
  const handleInvoiceClick = async (invoice) => {
    navigate(`?invoice=${encodeURIComponent(invoice.invoiceId)}`);
  };

  const columns: ColumnsType<Invoice> = [
    {
      title: "Invoice #",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      render: (text, record) => (
        <Typography.Link onClick={() => handleInvoiceClick(record)}>
          {text}
        </Typography.Link>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status: Invoice["status"]) => {
        let color = "green";
        if (status === "PENDING") {
          color = "gold";
        } else if (status === "OVERDUE") {
          color = "red";
        }
        return <Tag color={color}>{status?.toUpperCase()}</Tag>;
      },
      filters: [
        { text: "Paid", value: "PAID" },
        { text: "Pending", value: "PENDING" },
        { text: "Overdue", value: "OVERDUE" },
      ],
      onFilter: (value: string | number | boolean, record) =>
        record.status === value,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount, record) =>
        `${record.currency.value as string} ${amount.toFixed(2)}`,
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (balance, record) =>
        `${record.currency.value} ${balance.toFixed(2)}`,
      sorter: (a, b) => a.balance - b.balance,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (date) => new Date(date).toLocaleDateString(),
      sorter: (a, b) =>
        new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => new Date(date).toLocaleDateString(),
      sorter: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    },
    {
      title: "Actions",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      render: (text, record) => (
        <a href={record.invoiceLink} target="_blank" rel="noopener noreferrer">
          View
        </a>
      ),
    },
  ];

  return (
    <Table<Invoice>
      columns={columns}
      dataSource={invoices}
      loading={loading}
      rowKey="id"
      pagination={{
        defaultPageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} invoices`,
      }}
    />
  );
};

export default InvoiceTable;
