import React, { useEffect, useMemo, useState } from "react";
import { Empty, message, Spin, Table, Drawer, Tooltip } from "antd";
import axios from "axios";
import { useUserState } from "src/state/UserState";
import { useOrganizationState } from "src/state/OrganizationState";
import { CollaboratorAvatar } from "../../Global";
import { EmailBody } from "./EmailBody";

export const EmailThreads = () => {
  const [loading, setLoading] = useState(true);
  const [emailThreads, setEmailThreads] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null); // Store selected thread
  const [drawerVisible, setDrawerVisible] = useState(false); // Manage drawer visibility
  const { user } = useUserState(); // Assuming useUserState provides user data
  const { collaborators, pendingCollaborators } = useOrganizationState(); // Organization state

  // Combine all collaborators and pending collaborators
  const allInternalUsers = useMemo(
    () => [...(collaborators ?? []), ...(pendingCollaborators ?? [])],
    [collaborators, pendingCollaborators],
  );

  useEffect(() => {
    const fetchThreads = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_TRUCO_BACKEND}/gmail/fetch_threads`,
          {
            user_email: user.email, // Send user_email in the body
          },
        );
        setEmailThreads(response.data.email_threads || []);
      } catch (error) {
        message.error("Failed to fetch email threads.");
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };
    if (user?.email) fetchThreads();
  }, [user]);

  const columns = [
    {
      title: "Sent By",
      dataIndex: "from",
      key: "from",
      render: (from) => {
        // Find the user in allInternalUsers
        const participant = allInternalUsers.find(
          (collaborator) => collaborator.email === from,
        );

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {participant && <CollaboratorAvatar collaborator={participant} />}
            <span style={{ marginLeft: participant ? "8px" : "0" }}>
              {from}
            </span>
          </div>
        );
      },
    },
    {
      title: "Recipient",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "No. of Replies",
      dataIndex: "replies",
      key: "replies",
      render: (replies) => replies?.length || 0, // Display number of replies
    },
    {
      title: "Message",
      dataIndex: "body",
      key: "body",
      render: (body) => (
        <Tooltip
          title={<div dangerouslySetInnerHTML={{ __html: body }} />} // Full content on hover
          overlayStyle={{
            maxWidth: "400px", // Control the tooltip width
            whiteSpace: "normal", // Allow line breaks for long text
          }}
        >
          <div
            style={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 2, // Truncate to 2 lines
              maxWidth: "200px", // Match your column width
              cursor: "pointer",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </div>
        </Tooltip>
      ),
    },
  ];

  const handleRowClick = (thread) => {
    setSelectedThread(thread); // Set the selected thread
    setDrawerVisible(true); // Show the drawer
  };

  return (
    <Spin spinning={loading}>
      {!loading && emailThreads.length === 0 ? (
        <Empty description="No emails sent through Truco yet" />
      ) : (
        <Table
          columns={columns}
          dataSource={emailThreads.map((thread, index) => ({
            key: index,
            ...thread[0], // Show only the main message in the table
            replies: thread.slice(1), // Replies are the rest of the thread
          }))}
          onRow={(record) => ({
            onClick: () => handleRowClick(record), // Open drawer on row click
          })}
          pagination={false}
        />
      )}

      {/* Drawer for Viewing the Selected Thread */}
      <Drawer
        title={selectedThread?.subject || "Email Thread"}
        placement="right"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        width={800}
      >
        {selectedThread && (
          <EmailBody
            thread={selectedThread}
            getCollaborator={(email) =>
              allInternalUsers.find((user) => user.email === email)
            }
          />
        )}
      </Drawer>
    </Spin>
  );
};
