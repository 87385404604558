import { useState, useCallback } from "react";
import axios from "axios";
import { getFirebaseIdToken } from "src/firebaseAuth";
import { message } from "antd";

const BASE_URL = process.env.REACT_APP_TRUCO_BACKEND;

// Custom hook to manage POST requests with optional query params
const usePostData = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Stable postData function using useCallback
  const postData = useCallback(
    async (body = {}, queryParams = {}) => {
      setLoading(true);
      setError(null);

      try {
        // Get Firebase ID token for authentication
        const token = await getFirebaseIdToken();
        const response = await axios.post(url, body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: queryParams,
        });

        if (response.data.error) {
          throw new Error(response.data.error);
        }

        setData(response.data);
        return { data: response.data, loading: false, error: null };
      } catch (err) {
        message.error(err?.response?.data?.message);
        console.error(`Error posting to ${url}:`, err);
        setError(err);
        setData(null);
        return { data: null, loading: false, error: err };
      } finally {
        setLoading(false);
      }
    },
    [url],
  );
  return { data, loading, error, postData };
};

// Hook for deleting a clip with authentication
export const useDeleteClip = () => {
  const url = `${BASE_URL}/clips/delete`;
  const { data, loading, error, postData } = usePostData(url);

  const deleteClip = async (clipId, organizationId, playlistId) => {
    const body = { clipId, organizationId, playlistId };
    await postData(body);
    return { data, loading, error };
  };

  return deleteClip;
};

// Hook for adding or updating a clip with authentication
export const useAddOrUpdateClip = () => {
  const url = `${BASE_URL}/clips/upsert`;
  const { data, loading, error, postData } = usePostData(url);

  // Function to add or update a clip
  const addOrUpdateClip = async (
    clipId,
    organizationId,
    conversationId,
    playlistId,
    playlistTitle,
    created,
    clipData,
  ) => {
    const body = {
      clipId,
      organizationId,
      conversationId,
      playlistId,
      playlistTitle,
      created,
      clipData,
    };

    await postData(body);
    return { data, loading, error };
  };

  return addOrUpdateClip;
};

// Hook for adding or updating a playlist with authentication
export const useAddOrUpdatePlaylist = () => {
  const url = `${BASE_URL}/playlist/upsert`;
  const { data, loading, error, postData } = usePostData(url);

  // Function to add or update a playlist
  const addOrUpdatePlaylist = async (input) => {
    await postData(input);
    return { data, loading, error };
  };

  return addOrUpdatePlaylist;
};

// Hook for adding or updating a playlist with authentication
export const useAddOrUpdateTag = () => {
  const url = `${BASE_URL}/tags/upsert`;
  const { data, loading, error, postData } = usePostData(url);

  // Function to add or update a playlist
  const addOrUpdateTag = async (input) => {
    await postData(input);
    return { data, loading, error };
  };

  return addOrUpdateTag;
};

// Hook for deleting a clip with authentication
export const useDeleteTag = () => {
  const url = `${BASE_URL}/tags/delete`;
  const { data, loading, error, postData } = usePostData(url);

  const deleteTag = async (tagName, organizationId, playlistId) => {
    const body = { tagName, organizationId, playlistId };
    await postData(body);
    return { data, loading, error };
  };

  return deleteTag;
};

// Hook for deleting a clip with authentication
export const useMergeTags = () => {
  const url = `${BASE_URL}/tags/merge_playlist_tags`;
  const { data, loading, error, postData } = usePostData(url);

  const mergeTags = async (
    sourceTag,
    targetTag,
    organizationId,
    playlistId,
  ) => {
    const body = { sourceTag, targetTag, organizationId, playlistId };
    await postData(body);
    return { data, loading, error };
  };

  return mergeTags;
};

// API_KEY HOOKS

export const useGenerateApiKey = () => {
  const url = `${BASE_URL}/api-keys/generate`;
  const { postData } = usePostData(url);

  const generateApiKey = async () => {
    return await postData({});
  };

  return generateApiKey;
};

export const useListApiKeys = () => {
  const url = `${BASE_URL}/api-keys/list`;
  const { postData } = usePostData(url);

  const listApiKeys = async () => {
    return await postData({});
  };

  return listApiKeys;
};

export const useDeactivateApiKey = () => {
  const url = `${BASE_URL}/api-keys/deactivate`;
  const { postData } = usePostData(url);

  const deactivateApiKey = async (id) => {
    return await postData({ id });
  };

  return deactivateApiKey;
};

export default usePostData;
