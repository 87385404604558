import { TimeRangePickerProps } from "antd";
import {
  ACCESS_TYPE,
  ComparisonType,
  QUERY_PARAMS,
  TIME_INTERVAL,
} from "src/utils/enums";
import dayjs from "dayjs";
import { QueryConditions, UserType } from "./types";

import { color } from "src/styles/variables";
import { createTheme } from "@mui/material";
import CryptoJS from "crypto-js";

function isPlainObject(variable) {
  // Check if the variable is an object and not null
  if (typeof variable === "object" && variable !== null) {
    // Further check if it's a plain object by comparing its constructor name
    // This filters out complex objects like Arrays, Dates, and other built-in types
    // as well as instances of custom classes
    const proto = Object.getPrototypeOf(variable);
    return proto === Object.prototype || proto === null;
  }
  return false;
}

// Fix undefined values that could creep in. ideally we shouldn't need this at ideally @todo remove
export const cleanObject = (obj) => {
  const handleValue = (value) => {
    if (Array.isArray(value)) {
      return value.map(handleValue);
    } else if (isPlainObject(value)) {
      return cleanObject(value);
    } else {
      return value ?? null;
    }
  };

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, handleValue(value)]),
  );
};

export const isEmptyObject = (obj) => {
  return !obj || Object.keys(obj).length == 0;
};

export function formatTime(secs) {
  if (!secs) return null;
  let sec_num = parseInt(secs, 10);
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor(sec_num / 60) % 60;
  let seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
}

export function unformatTime(hms) {
  let multiplier = 1;
  let seconds = 0;
  hms
    .split(":")
    .slice()
    .reverse()
    .forEach((x) => {
      seconds += multiplier * x;
      multiplier *= 60;
    });

  return seconds;
}

export function humanize(str) {
  try {
    str = JSON.parse(str);
  } catch (e) {
    // do nothing
  }

  if (str === "") {
    return "N/A";
  } else if (typeof str === "string") {
    var i,
      frags = str
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Split camel case words
        .split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  } else if (typeof str === "number") {
    return parseFloat(str.toFixed(2));
  } else if (typeof str === "boolean") {
    return str ? "Yes" : "No";
  }
  return str;
}

export function unhumanize(str) {
  if (!str) return;
  return str
    .toLowerCase()
    .replace(/ /g, "_")
    .replace(/[^a-zA-Z0-9_]/g, "");
}

export const hasUpdateInfo = (
  analysisType,
  metricType,
  evaluation,
  comparison: ComparisonType,
) => {
  const updateKey = `${analysisType}.${metricType}`;
  const updateInfo = evaluation?.updates?.[updateKey];
  if (updateInfo) {
    const hasBeenUpdated =
      JSON.stringify(updateInfo[`old_${comparison}`]) !==
      JSON.stringify(updateInfo[`updated_${comparison}`]);

    return {
      hasBeenUpdated,
      oldValue: updateInfo[`old_${comparison}`],
      updatedValue: updateInfo[`updated_${comparison}`],
    };
  } else {
    return {
      hasBeenUpdated: false,
      oldValue: undefined,
      updatedValue: undefined,
    };
  }
};

export const getStartEndDateForTimeInterval = (timeInterval: TIME_INTERVAL) => {
  switch (timeInterval) {
    case TIME_INTERVAL.TODAY:
      return [dayjs(), dayjs()];
    case TIME_INTERVAL.YESTERDAY:
      return [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")];
    case TIME_INTERVAL.THIS_WEEK:
      return [dayjs().startOf("week"), dayjs()];
    case TIME_INTERVAL.THIS_MONTH:
      return [dayjs().startOf("month"), dayjs()];
    case TIME_INTERVAL.THIS_YEAR:
      return [dayjs().startOf("year"), dayjs()];
    case TIME_INTERVAL.LAST_WEEK:
      return [
        dayjs().subtract(1, "week").startOf("week"),
        dayjs().subtract(1, "week").endOf("week"),
      ];
    case TIME_INTERVAL.LAST_MONTH:
      return [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ];
    case TIME_INTERVAL.LAST_YEAR:
      return [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ];
    case TIME_INTERVAL.PREVIOUS_30_DAYS:
    default:
      return [dayjs().subtract(30, "day"), dayjs()];
  }
};

export const rangePresets: TimeRangePickerProps["presets"] = Object.keys(
  TIME_INTERVAL,
).map((item) => {
  return {
    label: TIME_INTERVAL[item],
    value: getStartEndDateForTimeInterval(
      TIME_INTERVAL[item as keyof typeof TIME_INTERVAL],
    ),
  };
}) as any;

export const getParticipatedChatsCondition = (
  userEmail: string,
): QueryConditions => {
  return {
    comparisonField: "evaluation.participants",
    comparisonOperator: "array-contains",
    value: userEmail,
  };
};

export const getOrganizationCondition = (
  organization: string,
): QueryConditions => {
  return {
    comparisonField: "organization",
    comparisonOperator: "==",
    value: organization,
  };
};

export const getOptions = (
  options: any[],
  optionFunction: any = null,
  sort: boolean = true,
) => {
  let formattedOptions = options.map((option) => {
    const cleanOption = option === undefined ? null : option;
    return optionFunction
      ? optionFunction(cleanOption)
      : {
          label: cleanOption,
          value: cleanOption,
        };
  });
  if (sort) {
    formattedOptions = Array.from(
      new Set(formattedOptions.map((option) => JSON.stringify(option))),
    )
      .sort()
      .map((option) => JSON.parse(option));
  }
  return formattedOptions;
};

export function filterUserFields(user): Partial<UserType> {
  // Directly assigning each field, defaulting to null if it's undefined

  const filteredUser: Partial<UserType> = {
    uid: user.uid,
    name: (user?.displayName || user?.name) ?? null,
    authProvider: user.authProvider,
    email: user.email,
    organization: user.organization ?? null,
    accessType: user.accessType ?? null,
    photoURL: user.photoURL ?? null,
    company: user.company ?? null,
    role: user.role ?? null,
    trialsLeft: user.trialsLeft ?? null,
    slack: user?.slack?.oauth?.incoming_webhook?.channel,
    googleCalendar: user?.googleCalendar?.id,
    outlookCalendar: user?.outlookCalendar?.id,
    zoom: !!user?.zoom,
    gmail: !!user?.gmail,
  };

  return cleanObject(filteredUser);
}

export function formatDateToHumanReadable(dateString) {
  if (typeof dateString !== "string" && isNaN(new Date(dateString).getTime())) {
    return "";
  }

  const inputDate = new Date(dateString);
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const inputDateMidnight = new Date(
    inputDate.getFullYear(),
    inputDate.getMonth(),
    inputDate.getDate(),
  );

  // Check if the date is today
  if (inputDateMidnight.getTime() === today.getTime()) {
    return inputDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  }

  // Calculate the start of the week for today
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - today.getDay());

  // Calculate the start of the week for the inputDate
  const inputDateStartOfWeek = new Date(inputDateMidnight);
  inputDateStartOfWeek.setDate(
    inputDateMidnight.getDate() - inputDateMidnight.getDay(),
  );

  // Check if the date is within this week
  if (
    inputDateMidnight >= startOfWeek &&
    inputDateMidnight <
      new Date(startOfWeek.getTime() + 7 * 24 * 60 * 60 * 1000)
  ) {
    return inputDate.toLocaleDateString("en-US", { weekday: "long" });
  }

  // For dates not today or this week
  return inputDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });
}

export const isLocalHost = () => {
  const hostName = window.location.hostname;
  return hostName.includes("localhost") || hostName.includes("127.0.0.1");
};

export const capitalizeFirstLetter = (string: string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};
export const capitalizeFirstLetters = (str: string) => {
  return str
    ?.split(" ")
    .map(
      (word) =>
        word
          .split("-") // Split by hyphen to handle hyphenated words
          .map((part) => part.charAt(0).toUpperCase() + part.slice(1)) // Capitalize first letter of each part
          .join("-"), // Join the parts back with hyphen
    )
    .join(" ");
};

export const isReviewerInCalibration = (calibration, currentUser) => {
  if (!calibration) return false;
  const reviewers = calibration?.reviewers || [];
  const excludedReviewers = calibration?.excluded_reviewers || [];

  const isExcluded = excludedReviewers?.includes(currentUser.email);
  if (isExcluded) {
    return false;
  }

  const isReviewer = reviewers?.includes(currentUser.email);
  const isReviewerRole =
    [ACCESS_TYPE.reviewer, ACCESS_TYPE.owner, ACCESS_TYPE.trucoAdmin].includes(
      currentUser?.accessType,
    ) && reviewers?.includes("reviewers");

  return isReviewer || isReviewerRole;
};

// Simple email validation function
export const isValidEmail = (email: string) => {
  return email && /\S+@\S+\.\S+/.test(email);
};

export const modifyQueryParams = (
  currentSearch,
  paramsToDrop: QUERY_PARAMS[] = [],
  paramsToAdd: {
    [key in QUERY_PARAMS]?: string;
  } = {},
) => {
  const searchParams = new URLSearchParams(currentSearch);

  paramsToDrop.forEach((param) => searchParams.delete(param));

  Object.entries(paramsToAdd).forEach(([key, value]) => {
    if (value !== undefined) {
      searchParams.set(key, value);
    }
  });
  return searchParams.toString() ? `?${searchParams}` : "";
};

export const orangeTheme = createTheme({
  palette: {
    primary: {
      main: color.orange,
    },
  },
});

export const getUsersEmailDictionary = (collaborators, pendingCollaborators) =>
  [...(collaborators ?? []), ...(pendingCollaborators ?? [])].reduce(
    (acc, user) => {
      acc[user.email] = user;
      return acc;
    },
    {},
  );

export const formatDateRange = (
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs,
): string => {
  const formattedStartDate = startDate.format("D MMM");
  const formattedEndDate = endDate.format("D MMM YYYY");

  if (
    startDate.month() === endDate.month() &&
    startDate.year() === endDate.year()
  ) {
    return `${startDate.format("D")} – ${endDate.format("D MMM YYYY")}`;
  }

  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const extractAndFormatTrackingDate = (id: string): string => {
  const [date, period] = id.split("_");
  const parsedDate = dayjs(date, "YYYY-MM-DD");
  let endDate;

  switch (period.toLowerCase()) {
    case "weekly":
      endDate = parsedDate.add(6, "day"); // Weekly period ends after 6 days
      break;
    case "monthly":
      endDate = parsedDate.add(1, "month").subtract(1, "day"); // Monthly period ends after a month minus one day
      break;
    case "yearly":
      endDate = parsedDate.add(1, "year").subtract(1, "day"); // Yearly period ends after a year minus one day
      break;
    default:
      endDate = parsedDate; // Default case if period is not recognized
  }

  return formatDateRange(parsedDate, endDate);
};

export const encryptId = (id) => {
  const encrypted = CryptoJS.AES.encrypt(
    id,
    process.env.REACT_APP_SECRET_ENCODING_KEY,
  ).toString();
  return encodeURIComponent(encrypted);
};

export const decryptId = (encryptedId) => {
  try {
    const decoded = decodeURIComponent(encryptedId); // Decode the URL-safe string
    const bytes = CryptoJS.AES.decrypt(
      decoded,
      process.env.REACT_APP_SECRET_ENCODING_KEY,
    );
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error("Error decrypting ID:", error);
    return null;
  }
};

export const getColorForValue = (value, options = null) => {
  const colors = [
    color.orange, // Color for the first speaker
    color.grayDark, // Color for the second speaker
    color.yellow,
    color.lightGreen,
    color.mediumGreen,
    color.red,
  ];

  const hash = (str) => {
    if (typeof str !== "string") {
      str = String(str);
    }
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32-bit integer
    }
    return Math.abs(hash);
  };

  let index;
  if (options) {
    index = options.findIndex((v) => v.toLowerCase() === value.toLowerCase());
  } else if (typeof value === "number") {
    index = value;
  } else {
    // for users that have email, names, or any other string value
    index = hash(value);
  }

  return colors[index % colors.length];
};

export function getTranscriptFromUtterances(utterances) {
  let transcriptString = "";
  let prevIdentifier = null;

  utterances.forEach((utterance) => {
    let identifier = utterance.speaker || utterance.channel || "";
    if (identifier !== prevIdentifier) {
      transcriptString += `\nSpeaker ${identifier}: ${utterance.transcript}`;
    } else {
      transcriptString += ` ${utterance.transcript}`;
    }
    prevIdentifier = identifier;
  });

  return transcriptString;
}

export function emailOrPhone(customerValue: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\+?[1-9]\d{1,14}$/;

  if (emailRegex.test(customerValue)) {
    return "email";
  } else if (phoneRegex.test(customerValue)) {
    return "phone";
  } else {
    return "customer";
  }
}
