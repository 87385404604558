import React, { useState } from "react";
import {
  Descriptions,
  Timeline,
  Card,
  Row,
  Col,
  Tag,
  Spin,
  Typography,
  Button,
  Popconfirm,
  message,
} from "antd";
import {
  MailOutlined,
  MessageOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  WarningOutlined,
  PhoneFilled,
  DollarOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useModal } from "src/state/UseModal";
import { spacing } from "src/styles/variables";
import { useFetchInvoices } from "src/hooks/useFetchData";
import { useOrganizationState } from "src/state/OrganizationState";
import { color } from "src/styles/variables";

const { Title, Text } = Typography;

const getStatusColor = (status?: string) => {
  switch (status?.toLowerCase()) {
    case "completed":
    case "successful":
      return color.green; // green
    case "failed":
    case "cancelled":
      return color.red; // red
    case "open":
      return color.yellow; // yellow
    case "pending":
    case "scheduled":
      return color.olive; // blue
    default:
      return color.gray; // gray
  }
};

interface TimelineEvent {
  id: string;
  type: string;
  date: Date;
  description: string;
  details?: string;
  isUpcoming: boolean;
  status?: string;
}

export const InvoiceModalContent: React.FC = () => {
  const { modalState, closeModal, setCustomBreadcrumb } = useModal();
  const { organization } = useOrganizationState();
  const invoiceId = modalState?.id;

  const {
    data: invoices,
    loading: loadingInvoice,
    refetch,
  } = useFetchInvoices(organization, invoiceId);

  const invoice = invoices?.data?.[0];

  const [upcomingActions, setUpcomingActions] = useState(
    invoice?.upcomingActions || [],
  );

  const handleCancelAction = (actionId: string) => {
    setUpcomingActions((prev) =>
      prev.map((action) =>
        action.id === actionId ? { ...action, status: "cancelled" } : action,
      ),
    );
    message.success("Action cancelled successfully");
  };

  const getTimelineIcon = (type: string) => {
    switch (type.toLowerCase()) {
      case "email":
        return <MailOutlined />;
      case "call":
      case "phone":
        return <PhoneFilled />;
      case "notice":
        return <WarningOutlined />;
      case "message":
        return <MessageOutlined />;
      case "payment":
        return <DollarOutlined />;
      case "dispute":
        return <ExclamationCircleOutlined />;
      default:
        return <ClockCircleOutlined />;
    }
  };

  const renderTimelineItems = () => {
    if (!invoice) return null;

    const events: TimelineEvent[] = [
      // Payment history events
      ...(invoice.paymentHistory?.map((payment) => ({
        id: `payment-${payment.date}`,
        type: "payment",
        date: new Date(payment.date),
        description: `Payment Received: ${invoice.currency.value} ${payment.amount.toFixed(2)}`,
        details: `Method: ${payment.method}`,
        isUpcoming: false,
        status: "completed",
      })) || []),

      // Contact attempt events
      ...(invoice.contactAttempts?.map((contact) => ({
        id: `contact-${contact.date}`,
        type: contact.type,
        date: new Date(contact.date),
        description: `Contact Attempt (${contact.type})`,
        details: `${contact.notes || ""}`,
        isUpcoming: false,
        status: contact.successful ? "successful" : "failed",
      })) || []),

      // Dispute events
      ...(invoice.disputeInfo
        ? [
            {
              id: `dispute-${invoice.disputeInfo.openDate}`,
              type: "dispute",
              date: new Date(invoice.disputeInfo.openDate),
              description: "Dispute Opened",
              details: `Reason: ${invoice.disputeInfo.reason.replace(/_/g, " ")}`,
              isUpcoming: false,
              status: invoice.disputeInfo.status,
            },
          ]
        : []),

      // Promise to pay events
      ...(invoice.promiseToPay
        ? [
            {
              id: `promise-${invoice.promiseToPay.promisedDate}`,
              type: "promise",
              date: new Date(invoice.promiseToPay.promisedDate),
              description: `Promise to Pay: ${invoice.currency.value} ${invoice.promiseToPay.amount.toFixed(2)}`,
              isUpcoming:
                new Date(invoice.promiseToPay.promisedDate) > new Date(),
              status: invoice.promiseToPay.status,
            },
          ]
        : []),

      // Upcoming actions
      ...upcomingActions.map((action) => ({
        id: action.id,
        type: action.type,
        date: new Date(action.scheduledDate),
        description: action.description,
        isUpcoming: action.status !== "cancelled",
        status: action.status || "scheduled",
      })),
    ].sort((a, b) => b.date.getTime() - a.date.getTime());

    return events.map((event) => (
      <Timeline.Item
        key={event.id}
        dot={getTimelineIcon(event.type)}
        color={
          event.status === "cancelled"
            ? color.red
            : event.isUpcoming
              ? color.green
              : color.gray
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <div>
            <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <Text
                strong
                style={{
                  textDecoration:
                    event.status === "cancelled" ? "line-through" : "none",
                  opacity: event.status === "cancelled" ? 0.5 : 1,
                }}
              >
                {event.description}
              </Text>
            </div>
            <Text
              type="secondary"
              style={{ fontSize: "0.9em", display: "block", marginTop: 4 }}
            >
              {event.date.toLocaleDateString()}
            </Text>
            {event.details && (
              <Text
                type="secondary"
                style={{ fontSize: "0.9em", display: "block" }}
              >
                {event.details}
              </Text>
            )}
            {event.status && (
              <div
                style={{ marginTop: 4, display: "flex", alignItems: "center" }}
              >
                <Text
                  style={{
                    fontSize: "0.9em",
                    color: getStatusColor(event.status),
                    textTransform: "capitalize",
                  }}
                >
                  {event.status}
                </Text>
              </div>
            )}
          </div>
          {event.isUpcoming && event.status !== "cancelled" && (
            <Popconfirm
              title="Cancel this action?"
              description="This action will be marked as cancelled."
              onConfirm={() => handleCancelAction(event.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="text"
                danger
                icon={<DeleteOutlined />}
                size="small"
              />
            </Popconfirm>
          )}
        </div>
      </Timeline.Item>
    ));
  };

  if (!invoice) {
    return <Spin />;
  }

  return (
    modalState && (
      <>
        <div style={{ marginBottom: 16 }}>
          <Row gutter={[8, 8]} align="middle">
            <Col>
              <Title level={4} style={{ margin: 0 }}>
                Invoice #{invoice.invoiceNumber}
              </Title>
            </Col>
            <Col>
              <Tag color={getStatusColor(invoice.status)}>{invoice.status}</Tag>
            </Col>
          </Row>
        </div>

        <Row gutter={[16, 16]}>
          <Col span={24} md={12}>
            <Card>
              <Descriptions
                title="Invoice Details"
                column={1}
                size="small"
                labelStyle={{ fontWeight: 500 }}
                contentStyle={{ textAlign: "right" }}
              >
                <Descriptions.Item label="Amount">
                  {invoice.currency.value} {invoice.amount.toFixed(2)}
                </Descriptions.Item>
                <Descriptions.Item label="Balance">
                  {invoice.currency.value} {invoice.balance.toFixed(2)}
                </Descriptions.Item>
                <Descriptions.Item label="Due Date">
                  {new Date(invoice.dueDate).toLocaleDateString()}
                </Descriptions.Item>
                <Descriptions.Item label="Created At">
                  {new Date(invoice.createdAt).toLocaleDateString()}
                </Descriptions.Item>
                {invoice.balance > 0 &&
                  new Date(invoice.dueDate) < new Date() && (
                    <Descriptions.Item label="DSO">
                      {Math.floor(
                        (new Date().getTime() -
                          new Date(invoice.dueDate).getTime()) /
                          (1000 * 60 * 60 * 24),
                      )}{" "}
                      days
                    </Descriptions.Item>
                  )}
              </Descriptions>
            </Card>

            {invoice?.complianceIssues?.length > 0 && (
              <Card title="Compliance Issues" style={{ marginTop: spacing.lg }}>
                {invoice.complianceIssues.map((issue, index) => (
                  <Tag
                    key={index}
                    color={
                      issue.severity === "high"
                        ? color.red
                        : issue.severity === "medium"
                          ? color.orange
                          : color.yellow
                    }
                    style={{
                      marginBottom: 8,
                      display: "inline-flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    {issue.type}: {issue.description}
                  </Tag>
                ))}
              </Card>
            )}
          </Col>

          <Col span={24} md={12}>
            <Card
              title={
                <span>
                  <ClockCircleOutlined style={{ marginRight: 8 }} />
                  Activity Timeline
                </span>
              }
            >
              <Timeline style={{ marginTop: spacing.md }}>
                {renderTimelineItems()}
              </Timeline>
            </Card>
          </Col>
        </Row>
      </>
    )
  );
};

export default InvoiceModalContent;
